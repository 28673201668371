.card {
    @extend .container--main;
    background: transparent;
    padding-top: 10px;
    @include flexbox;
    @include flex-direction(column);

    &__top-bar {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-end); }

    &__content {
        @extend .table;
        border: solid 1px #5b4f48;
        padding: 5px;
        @include flexbox;
        @include flex-direction(column);
        @include flex(1);
        @include respond-to('small') {
            padding: 20px; }

        &__header {
            width: 100%;
            background: #000;
            @include align-items(center);
            padding: 5px 10px;

            &__field {
                @extend .table__header__field;
                span {
                    font-weight: 600;
                    color: #fff; } } }

        &__editor {
            @include flex(1);
            .CodeMirror {
                height: 500px; } }

        &__buttons {
            text-align: right;
            margin-top: 10px; }

        &__button {
            @extend .table__form__trigger;
            margin-left: 5px;
            padding-left: 25px;
            padding-right: 25px;
            &.active {
                border-color: #b5a799; } }

        &__error {
            @extend .text--regular;
            color: $orange-text;
            margin-bottom: 10px;
            font-weight: 500; } }

    &__list {

        &__title {
            @extend .table__title; } } }
