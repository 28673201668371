.login {
    @extend .modal__box;
    max-width: 450px;

    &__close {
        @extend .modal__close; }

    &__top {
        @extend .modal__top; }

    &__content {
        @extend .modal__content;
        padding: 20px 10px 10px 10px;
        @include respond-to(small) {
            padding: 50px 10px 10px 10px; } }

    &__inner {
        @extend .modal__inner;
        padding: 25px 12px 12px;
        font-weight: 600;
        font-size: 0.9rem; }

    &__header {
        @extend .modal__header; }

    &__field {
        padding: 0;
        margin-bottom: 5px;
        @include respond-to(small) {
            padding: 0 70px 0 10px;
            margin-bottom: 20px; } }

    &__buttons {
        @extend .form__buttons; }

    &__button {
        @extend .form__button;
        &--clear {
            @extend .form__button--clear; } }

    &__error {
        @extend .form__field__error;
        text-align: center;
        font-size: 0.83rem;
        font-weight: 500;
        margin-bottom: 10px;
        @include respond-to(small) {
            margin-bottom: 20px; } } }
