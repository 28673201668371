button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-color: rgba(0,0,0,0);
  border-radius: 0;
  opacity: 1;
  &.disabled {
    pointer-events: none;
    opacity: 0.5; } }

.button {
  &--full-framed {
    @extend .text--regular;
    border: solid 1px $orange-text;
    display: inline-block;
    position: relative;
    padding: 10px 35px;
    font-weight: 600;
    font-size: 0.62rem;
    transition: all 0.2s ease-in-out;
    &:hover, &:focus {
      text-decoration: none;
      color: $orange-text; }
    &:active {
      color: #fff;
      // background-color: #1f1d1b
      border-color: #fff;
      &::before, &::after {
        background: #fff; } }
    @include respond-to('medium') {
      font-size: 0.66rem;
      padding: 10px 45px; } }


  &--full-framed--reversed {
    @extend .button--full-framed;
    border: solid 1px #fff;
    color: #fff;
    &:hover, &:focus {
      border-color: $orange-text; }
    &:active {
 } }      // background-color: #1f1d1b

  &--framed {
    text-transform: uppercase;
    padding: 9px 10px;
    @extend .text--regular;
    border-top: solid 1px $orange-text;
    border-bottom: solid 1px $orange-text;
    width: 100%;
    display: inline-block;
    position: relative;
    @include respond-to('small') {
      padding: 12px 10px; }
    &, span {
      &::before, &::after {
        content: "";
        width: 1px;
        position: absolute;
        transition: all 0.4s ease-in-out;
        background: $orange-text;
        height: 7px; } }
    &::before {
      left: 0;
      top: 0; }
    &::after {
      left: 100%;
      top: -1px;
      height: 8px; }
    &:hover, &:focus {
      text-decoration: none;
      color: $orange-text; }
    &:hover, &:focus, &:active {
      &, span {
        &::before, &::after {
          height: 100%; } } }
    &:active {
      color: #fff;
      // background-color: #1f1d1b
      border-color: #fff;
      &, span {
        &::before, &::after {
          background: #fff; } } }
    span {
      display: block;
      &::before {
        left: 0;
        bottom: 0; }
      &::after {
        left: 100%;
        bottom: -1px;
        height: 8px; } }

    &.disabled {
      opacity: 0.5; } } }

.arrow {
	display: inline; }
.arrow:before {
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: black transparent transparent transparent;
  display: inline-block;
  vertical-align: middle; }

.arrow--up {
	@extend .arrow; }
.arrow--up:before {
  @extend .arrow:before;
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent black transparent;
  vertical-align: middle; }


