.table {
    width: 100%;
    padding: 0;
    margin: 0;
    // max-height: 600px
    overflow-y: auto;
    @include respond-to('large') {
        max-height: none; }

    &__title {
        @extend .text--regular;
        background: #fff;
        color: #414141;
        font-weight: 600;
        padding: 7px 27px;
        font-size: 0.7rem;
        margin-right: 10px; }

    &__item {
        width: 100%;
        @include flexbox;
        @include align-items(center);
        margin-bottom: 10px;
        @include flex-direction(column);
        position: relative;
        &:after {
            content: '';
            background: #5b4f48;
            display: inline-block;
            height: 1px;
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc(100% - 100px);
            @include respond-to('large') {
                display: none; } }
        @include respond-to('large') {
            margin-bottom: 0;
            padding: 5px 10px;
            border-bottom: solid 1px #5b4f48;
            @include flex-direction(row); }

        &--link {
            @extend .table__item;
            padding: 0;
            border-bottom: none;
            margin-top: 5px;
            @include flexbox;
            &:after {
                display: none; }

            & > a {
                padding: 5px 10px;
                border: solid 1px #5b4f48;
                @include flexbox;
                @include flex(1);
                color: #fff;
                width: 100%;
                @include flex-direction(column);
                @include respond-to('large') {
                    width: auto;
                    @include flex-direction(row); }
                &:hover, &:focus {
                    color: $orange-text;
                    border-color: #b68245;
                    background-color: #483f36;
                    text-decoration: none;
                    .table__item__field {
                        color: $orange-text; } } } }

        &__tooltip {
            @extend .text--regular;
            position: absolute;
            max-width: 300px;
            border: solid 1px #cccccc;
            background: #373028;
            color: #fefefe;
            font-weight: 500;
            font-size: 0.73rem;
            padding: 5px;
            border-radius: 5px;
            line-height: 1rem;
            top: 100%;
            left: 0;
            z-index: 2;
            @include respond-to('large') {
                top: 0;
                left: 200px; }
            &:before {
                content: '';
                display: inline-block;
                position: relative;
                width: 0;
                height: 0;

                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 7px solid #fff;
                margin-right: -9px;

                left: 0;
                top: -18px;

                @include respond-to('large') {
                    left: -18px;
                    top: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 7px solid #fff;
                    margin-right: -12px; } } }


        &__field {
            position: relative;
            @include flex(1);
            font-weight: 500;
            @include flexbox;
            @include align-items(center);
            width: 100%;
            line-height: 2rem;
            &:before {
                content: attr(data-header);
                display: inline-block;
                background-color: #1f1d1b;
                @include flex(0 0 80px);
                padding-left: 10px;
                margin-right: 10px;
                font-weight: 500;
                color: #a5a5a5;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            @include respond-to('large') {
                line-height: unset;
                &:before {
                    display: none; } }

            &--plain {
                @extend .table__item__field;
                color: $orange-text;
                font-weight: 400; } } }

    &__header {
        width: 100%;
        background: #1f1d1b;
        @include align-items(center);
        padding: 5px 10px;
        display: none;
        @include respond-to('large') {
            @include flexbox; }

        &__field {
            @extend .text--regular;
            @include flex(1);
            font-weight: 500;
            color: #ababab; } }

    &__form {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        height: 100%;
        @include flex(1);

        &.active {
            cursor: auto; }

        .hover {
            @extend .offscreen; }

        &[disabled] {
            pointer-events: none; }

        &:hover, &:focus {
            .hover {
                @extend .onscreen; }
            .table__form__field__input {
                border: solid 1px $orange-text;
                background: #373028;
                color: $orange-text; }
            .table__form__field__select {
                &.disabled {
                    .select {
                        &--table {
                            &__control {
                                border: solid 1px $orange-text;
                                background: #373028;
                                .select--table__indicators {
                                    display: block; } }
                            &__single {
                                &-value {
                                    color: $orange-text; } } } } } }
            .select--table__indicators {
                display: inline; } }

        &.active {
            .hover {
                @extend .onscreen; }

            .users__form__button {
                background: #1f1d1b;
                border-color: #fff;
                font-size: 0.43rem;
                &[disabled] {
                    color: #a5a5a5; } } }

        &__checkbox {
            &__label {
                @extend .offscreen; } }

        &__input {
            &__label {
                @extend .offscreen; } }

        &__select {
            &__label {
                @extend .offscreen; } }

        &__field {
            display: inline-block;
            float: left;

            &__input {
                @extend .text--regular;
                font-weight: 400;
                width: 100%;
                padding-left: 2px;
                padding-right: 2px;
                line-height: 1rem;
                border: solid 1px #fff;
                background: #1f1d1b;
                color: #fff;

                &[disabled] {
                    cursor: pointer;
                    background: transparent;
                    border: solid 1px transparent;
                    color: $orange-text;
                    text-overflow: ellipsis; } }

            &__select {
                &.disabled {
                    pointer-events: none;

                    .select {
                        &--table {
                            &__control {
                                background: transparent;
                                border: solid 1px transparent;
                                .select--table__indicators {
                                    display: none; } }
                            &__single {
                                &-value {
                                    color: $orange-text; } } } } } }

            &__error {
                @extend .text--regular;
                color: $orange-text;
                font-weight: 600;
                padding: 0 2px;
                font-size: 0.7rem; }

            &__checkbox {
                &--hidden {
                    @extend .checkbox--hidden; }
                &--visible {
                    @extend .checkbox--visible;
                    background: #845b2a;
                    border: solid 1px #cda875;
                    &[disabled] {
                        pointer-events: none; } }

                &-container {
                    @extend .checkbox-container; } } }

        &__buttons {
            @include flexbox;
            float: left;

            &--hidden {
                @extend .offscreen; } }

        &__button {
            border: solid 1px $orange-text;
            background: #483f36;
            text-align: center;
            border-left: none;
            width: 25px;
            padding: 0;
            color: #fff;
            font-size: 0.73rem;
            line-height: calc(1rem + 2px); }

        &__activate {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            &.active {
                z-index: -1; } }

        &__trigger {
            background: #413a35;
            border: solid 1px #6f6153;
            color: #fff;
            font-weight: 600;
            font-size: 0.73rem;
            padding: 3px 17px;
            white-space: nowrap;
            transition: all 0.2s ease-in-out;
            &:hover, &:focus {
                color: $orange-text;
                border-color: $orange-text; }
            &:active {
                color: #fff;
                background: #1f1d1b;
                border-color: #fff; } } } }
