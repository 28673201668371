.cards {
    @extend .container--main;
    padding-top: 10px;
    @include flexbox;
    @include flex-direction(column);
    @include respond-to('large') {
        max-height: calc(100vh - 240px);
        padding-bottom: 0; }

    &__top-bar {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-end); }

    &__new-text {
        @extend .table__form__trigger;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        span {
            margin-left: 20px; } }

    &__list {
        @extend .table;
        list-style: none;
        padding: 5px;
        border: solid 1px #5b4f48;
        @include flex(1);
        @include respond-to('small') {
            padding: 20px; }

        &__title {
            @extend .table__title; }

        &__item {
            @extend .table__item--link;
            @include flex-direction(row);
            @include align-items(stretch);

            &__trigger {
                @extend .table__form__trigger;
                width: 100%;
                height: 100%; }

            &__tooltip {
                @extend .table__item__tooltip;
                left: 100px;
                @include respond-to('small') {
                    left: 200px; } }

            & > a {
                position: relative;
                &:hover, &:focus {
                    .cards__list__item__field__img {
                        border-color: #b68245;
                        &:before, &:after {
                            background: #b68245; } }
                    .icon {
                        color: #fff; } } }

            &__field {
                @extend .table__item__field;
                font-weight: 600;

                &:before {
                    @include respond-to('small') {
                        @include flex(0 0 150px); } }

                &--name {
                    @extend .cards__list__item__field;
                    @include flex(1);
                    &:before {
                        text-transform: none; }
                    @include respond-to('large') {
                      @include flex(0 1 400px); } }

                &--update {
                    @extend .table__item__field;
                    @include flex(0 1 100px);
                    &:before {
                        text-transform: none;
                        display: none; }
                    @include respond-to('large') {
                      @include flex(0 1 150px); } } } }

        &__header {
            @extend .table__header;

            &__field {
                @extend .table__header__field;
                &--name {
                    @extend .cards__list__header__field;
                    @include flex(0 1 400px); } } } } }




