.select {
    &--nooption {
        color: white;
        font-size: 0.66rem; }

    &--table {
        &__control {
            border: solid 1px #fff;
            background: #1f1d1b;
            color: #fff;
            @include flexbox;
            height: calc(1rem + 4px);
            cursor: pointer;
            width: 100%;
            min-width: 80px;
            &--is-disabled {
                background: transparent;
                border: solid 1px transparent;
                .select--table__indicators {
                    display: none; } } }
        &__value {
            &-container {
                padding-left: 5px;
                @include flex(0 1 100%);
                @include flexbox;
                @include align-items(flex-start);
                overflow: hidden; } }
        &__single {
            &-value {
                padding: 0 2px;
                line-height: calc(1rem + 2px);
                text-align: left;
                font-weight: 500;
                font-size: 0.83rem;

                &--is-disabled {
                    color: $orange-text; } } }

        &__indicator {
            padding: 0 4px;
            line-height: calc(1rem + 2px);
            margin-top: -2px;

            svg {
                width: 12px;
                height: 12px; }
            &-separator {
                display: none; } }
        &__menu {
            position: absolute;
            top: 100%;
            left: 0;
            background: #373028;
            width: 100%;
            border: solid 1px #6F6153;
            border-top: none;
            z-index: 2;
            // max-height: 200px
            // overflow: auto
            &-list {
                padding: 2px 0;
                max-height: 200px !important; } }
        &__option {
            padding: 4px 22px;
            font-size: 0.83rem;
            line-height: 1rem;
            color: #fff;
            cursor: pointer;
            position: relative;
            text-align: left;
            font-weight: 500;
            &--is-selected {
                color: $orange-text;
                &:before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 7px;
                    display: inline-block;
                    @include transform-rotate(45deg);
                    height: 6px;
                    width: 3px;
                    border-bottom: 1px solid #fff;
                    border-right: 1px solid #fff; } }
            &--is-focused {
                background: #1f1d1b; } }

        &__input {
            position: absolute;
            top: 3px; } } }

