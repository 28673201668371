@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin transform-rotate($deg) {
  transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg); }

@mixin box-shadow($val) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val; }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); } //IE8

@mixin round-border($val) {
  border-top-left-radius: $val;
  border-top-right-radius: $val;
  border-bottom-right-radius: $val;
  border-bottom-left-radius: $val; }

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values; }

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction; }

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap; }

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify; }

@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align; }

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align; }

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align; }

@mixin justify-self($align) {
  -webkit-justify-self: $align;
  -moz-justify-self: $align;
  -ms-justify-self: $align;
  justify-self: $align; }

@mixin animation($type, $time) {
  -webkit-animation: $type $time;
  -moz-animation: $type $time;
  -ms-animation: $type $time;
  -o-animation: $type $time;
  animation: $type $time; }

@mixin list-columns($num, $gap) {
  -moz-column-count: $num;
  -moz-column-gap: $gap;
  -webkit-column-count: $num;
  -webkit-column-gap: $gap;
  column-count: $num;
  column-gap: $gap; }

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content; } } }

//breakpoints

$breakpoints: ("small": (min-width: 480px), "to-medium": (max-width: 768px), "medium": (min-width: 768px), "desktop": (min-width: 1024px), "to-large": (max-width: 1365px), "to-desktop": (max-width: 1024px), "large": (min-width: 1366px), "xlarge": (min-width: 1850px)) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content; } }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}."; } }
