.w10 {
    width: 10px; }

.themes {
    &__split-container {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        min-height: 100px;
        margin: 20px 0;
        @include respond-to('large') {
            flex-direction: row;
            max-height: calc(100vh - 260px);
            margin: 20px 0 0;
            overflow-x: hidden;
            overflow-y: auto; } }

    &__part {
        width: 100%;
        @include respond-to('large') {
            &:nth-child(even) {
                margin-left: 10px;
                border-left: solid 1px #6a5d50;
                padding-left: 10px;
                height: fit-content;
                min-height: calc(100vh - 260px); } } }

    &__item {
        &--folder {
            @extend .sources__item--folder;
            max-width: fit-content;
            margin-left: 22px;
            >.manage-sources__name {
                margin: 4px 22px; }

            &--active {
                @extend .themes__item--folder;
                cursor: pointer;
                &:hover {
                    border-color: #ba894f;
                    background: #423b35;
                    color: #f5f7f6; } } } } }
