.checkbox {
    &--hidden {
        border: 0;
        clip: rect(0 0 0 0);
        clippath: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px; }
    &--visible {
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 12px;
        transition: all 150ms;
        min-width: 16px;
        background: #000;
        position: relative;
        .icon {
            position: absolute;
            top: 1px;
            left: 1px;
            font-size: 0.5rem; } }
    &-container {
        @include flexbox;
        @include align-items(center); } }
