.annotation {
    &__texts-list {
        &__header {
            background: #1f1d1b;
            align-items: center;
            padding: 5px 10px;
            display: none;
            @include respond-to('large') {
                @include flexbox; }

            &__field {
                flex: 1 1;
                font-weight: 500;
                color: #ababab;

                &--name {
                    flex: 0 1 400px;
                    font-weight: 500;
                    color: #ababab; } } }

        &__item {
            &__field {
                @extend .manage-texts__list__item__field;
                &:before {
                    flex: 0 0 230px;
                    align-self: stretch; }

                &--name {
                    @extend .manage-texts__list__item__field--name;
                    &:before {
                        flex: 0 0 230px;
                        align-self: stretch; } } } } }

    &__content {
        @extend .manage-texts;
        padding-bottom: 0;
        margin-bottom: -10px; }

    &__sentences-list {
        @extend .manage-texts__list;
        padding: 10px 20px 20px;

        @include respond-to('large') {
            flex: 1 0 100px;
            padding: 20px; }

        // annotation__sentences-list__top-bar--options
        &__header {
            @extend .manage-texts__list__header;
            display: none;
            @include respond-to('large') {
                display: grid;
                grid-template-columns: 160px 1fr 350px; }
            &--field {
                font-weight: 500;
                color: #ababab; } }

        &__top-bar {
            @extend .manage-texts__top-bar;
            align-items: baseline;
            justify-content: start;
            max-width: fit-content;
            &--options {
                display: grid;
                grid-template-columns: 200px 200px;
                gap: 30px;
                margin-left: 30px;
                align-items: center;
                >.form__field {
                    >.form__field__label {
                        text-align: left;
                        font-size: 0.75rem;
                        font-weight: 400;
                        flex: 0 0 60px; }
                    >.form__field__select {
                        >.select--table__control {
                            border: solid 1px #6F6153;
                            background: #484036; } } } } }

        &__item {
            // @extend .manage-texts__list__item
            margin-bottom: 10px;
            margin-top: 10px;
            &:last-of-type {
                margin-bottom: 0; }

            &--link {
                font-weight: 400;
                color: #fff;
                padding: 5px;
                border: solid 1px #5b4f48;
                display: grid;
                grid-template-columns: 200px 1fr;
                grid-auto-rows: 30px 30px 45px;

                @include respond-to('large') {
                    display: grid;
                    grid-template-columns: 160px 1fr 350px;
                    grid-auto-rows: auto; }

                &:hover {
                    color: #e19d4d;
                    border-color: #b68245;
                    background-color: #483f36;
                    text-decoration: none; } }

            &--field {
                padding: 5px 10px;
                max-width: 100%;
                min-height: 16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include respond-to('large') {
                    padding: 0;
                    max-width: 90%; } }

            &--description {
                background: #1f1d1b;
                color: #a5a5a5;
                padding: 5px 10px;
                @include respond-to('large') {
                    display: none; } } } }

    &__checkbox {
        cursor: pointer;
        label {
            cursor: pointer;
            font-size: 0.75rem;
            font-weight: 400; } }

    &__pagination {
        justify-content: flex-end;
        margin-bottom: 15px;
        @include respond-to('large') {
            margin-bottom: -15px; } } }
