.cookies {
    background: #181616;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    z-index: 9999;
    text-align: center;
    &__header {
        @extend .text--additional;
        font-size: 0.75rem;
        color: #d6ac74;
        letter-spacing: 0;
        margin: 0 0 5px 0;
        font-weight: 900;
        span {
            margin-right: 7px;
            font-size: 0.85rem; } }
    &__desc {
        @extend .text--additional;
        letter-spacing: 0;
        line-height: 0.8rem;
        line-height: 0.9rem;
        max-width: 480px;
        margin: 0 auto;
        @include respond-to('large') {
            max-width: none; }
        &__link {
            @extend .text--additional;
            @extend .link--light;
            letter-spacing: 0;
            margin-left: 3px; } }
    &__accept {
        &__button {
            @extend .button--full-framed--reversed;
            text-transform: uppercase;
            padding: 8px 30px;
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 0.6rem; } } }
