.manage-sources {

    &__icon {
        width: 23px;
        height: 15px;
        content: "";
        position: absolute;
        top: 6px;
        &::after {
            content: "";
            position: absolute;
            right: 1px;
            top: 7px;
            height: 1px;
            width: 8px;
            background: #5c514b; }

        &--minus {
            @extend .manage-sources__icon;
            cursor: pointer;
            background: url(../images/icons/icon-171.svg) no-repeat left top;
            background-size: 15px 15px; }

        &--plus {
            @extend .manage-sources__icon;
            cursor: pointer;
            background: url(../images/icons/icon-170.svg) no-repeat left top;
            background-size: 15px 15px; }

        &--none {
            &::after {} } }

    &__wrapper {
        position: relative;
        @include respond-to(large) {
            max-height: calc(100vh - 250px);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0;
            margin-top: 10px; } }

    &__loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #3730288f;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100; }

    &__item {
        position: relative;
        &--folder {
            background: #f5f7f6;
            color: #414141;
            padding: 2px;
            margin-bottom: 10px;
            font-size: 0.5rem;
            line-height: 1.15;
            border: solid 1px #5b4f48;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include respond-to(small) {
                font-size: 0.625rem; }
            @include respond-to(medium) {
                flex-direction: row; } }

        &--item {
            @extend .manage-sources__item--folder;
            background: transparent;
            color: #f5f7f6;
            margin-left: 22px;
            @include respond-to(small) {
                font-size: 0.625rem; }
            &--active {
                @extend .manage-sources__item--item;
                cursor: pointer;
                &:hover {
                    border-color: #ba894f;
                    background: #423b35; } } } }

    &__name {
        margin: 4px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }

    &__arrows {
        border-left: solid 1px #6f6154;
        display: flex; }

    &__vl {
        border-left: 1px solid #6f6154;
        height: calc(100% - 8px);
        align-self: center;
        margin: 0 5px; }

    &__button {
        color: inherit;
        padding: 0 10px;
        &:hover {
            color: $orange-text;
            &.arrow {
                &:before {
                    border-color: $orange-text transparent transparent transparent; } }
            &.arrow--up {
                &:before {
                    border-color: transparent transparent $orange-text transparent; } } }

        &.arrow {
            &:before {
                border-color: #cecfcf transparent transparent transparent; }

            &:disabled {
                pointer-events: none;
                &:before {
                    border-color: #5f5f5f transparent transparent transparent; } } }

        &.right-border {
            border-right: solid 1px #6f6154;
            padding-right: 10px; }

        &.arrow--up {
            &:before {
                border-color: transparent transparent #cecfcf transparent; }

            &:disabled {
                pointer-events: none;
                &:before {
                    border-color: transparent transparent #5f5f5f transparent; } } } }

    &__buttons {
        align-self: stretch;
        display: flex;
        justify-content: flex-end;
        padding: 4px 0;
        border-top: solid 1px #6f6154;
        @include respond-to(medium) {
            justify-content: stretch;
            border-left: solid 1px #6f6154;
            border-top: none;
            padding: 0; } }

    &.modal {
        .modal {
            &__box {
                max-width: 580px; } } } }
