.text {
  &--regular {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-weight: 700;
    font-size: 0.83rem; }

  &--fancy {
    font-family: 'PT Serif', sans-serif;
    color: #fff;
    font-weight: 700;
    font-size: 1.85rem; }

  &--additional {
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 0.66rem;
    letter-spacing: 2px; } }
