.text-tabs {
    &__tabs {
        @extend .container--full;
        &__list {
            list-style: none;
            margin: 20px 0 0 0;
            padding: 0;
            overflow-y: auto;
            @include flexbox;
            @include align-items(stretch);
            &-item {
                display: inline-block;
                @include flex(1);
                &:last-of-type {
                    .text-tabs__tabs__list-item__link {
                        // border-right: solid 1px #6f6153
                        &:hover, &:focus {
                            border-color: $orange-text; }
                        &:active {
                            border-color: #fff; } } }
                &.active {
                    .text-tabs__tabs__list-item__link {
                        background-color: #fff;
                        color: #ac6b1f;
                        font-weight: 700;
                        border-color: #fff;
                        &:after {
                            content: '';
                            background-color: #ac6b1f;
                            display: inline-block;
                            height: 3px;
                            width: calc(100% - 6px);
                            position: absolute;
                            bottom: -1px;
                            left: 3px; } } }

                &__link {
                    @extend .text--regular;
                    @extend .button--full-framed--reversed;
                    text-align: center;
                    width: 100%;
                    border-color: #6f6153;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    position: relative;
                    padding: 10px;
                    white-space: nowrap;
                    cursor: pointer;
                    &:hover, &:focus {
                        border-right: solid 1px $orange-text;
                        border-bottom: solid 1px $orange-text; }
                    &:active {
                        border-color: #fff;
                        border-right: solid 1px #fff;
                        border-bottom: solid 1px #fff; } } } } }
    &__buttons {
      text-align: right;
      margin-top: 10px; }
    &__button {
      @extend .table__form__trigger;
      margin-left: 5px;
      padding-left: 25px;
      padding-right: 25px;
      font-weight: 500;
      &.active {
          border-color: #b5a799; } }
    &__error {
        @extend .text--regular;
        color: $orange-text;
        margin: 5px 0;
        font-weight: 500; }
    &__image-upload {
      text-align: right;
      margin-top: 20px;
      &__field {
        display: inline-block;
        width: 100%;
        text-align: right;
        .form__field {
          display: inline-block;
          &__file {
            right: 0;
            left: auto;
            &--visible {
              text-align: right; } }
          &__label {
            text-align: right;
            margin-bottom: 3px;
            display: inline-block;
            @include flex(0 1 auto); } } }
      &__buttons {
        @extend .form__buttons;
        margin-top: 10px; }
      &__button {
        @extend .form__button;
        &[disabled] {
          opacity: 0.5;
          pointer-events: none; } } }
    &__content {
      @include flex(1);
      padding-left: 10px;
      padding-right: 10px; }
    &__editor {
      @include flex-direction(column);
      overflow: auto;
      padding-top: 20px;
      @extend .text--regular;
      font-size: 0.8rem;
      line-height: 1.1rem;
      font-weight: 500;
      h1 {
        font-size: 1.2rem; }
      h2 {
        font-size: 1.1rem; }
      h3 {
        font-size: 1rem; }
      a {
        @extend .link--light;
        display: inline-block;
        &:after {
          bottom: 2px; }
        &.form__button {
          margin: 2px 0;
          &:after {
            display: none; } } }
      &__static {
        word-wrap: break-word;

        figure {
          margin: 0; }
        table {
          border-collapse: collapse; }
        th, td {
          padding: 10px; } } } }
