.home {
    @extend .container--main;
    @extend .container--full;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    @extend .text--fancy;
    padding-top: 50px;

    &__main {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        width: 100%; }

    &__separator {
        &--left {
            height: 1px;
            background: linear-gradient(to right, transparent, rgba(206, 151, 86, 0.6));
            @include flex(1);
            position: relative;
            top: 9px;
            margin-right: 10px;
            @include respond-to('medium') {
                top: 16px;
                margin-right: 0; }
            @include respond-to('large') {
                top: 11px;
                margin-right: 5px; } }

        &--right {
            height: 1px;
            background: linear-gradient(to right, rgba(206, 151, 86, 0.6), transparent);
            @include flex(1);
            position: relative;
            top: 9px;
            margin-left: 10px;
            @include respond-to('medium') {
                top: 16px;
                margin-left: 0; }
            @include respond-to('large') {
                top: 11px;
                margin-left: 5px; } } }

    &__header {
        text-align: center;
        position: relative;
        @extend .with-lines;
        margin: 10px 0;
        @include respond-to('medium') {
            margin: 0.67em 0; }

        &__main {
            font-size: 2.5rem;
            position: relative;
            display: block;
            letter-spacing: 2px;
            margin-bottom: 7px;
            @include respond-to('medium') {
                display: inline-block;
                top: 3px;
                margin-bottom: 0; }
            @include respond-to('large') {
                font-size: 3.6rem; } }

        &__sub {
            text-transform: uppercase;
            margin: 0 auto;
            position: relative;
            font-weight: 400;
            display: block;
            letter-spacing: 2px;
            width: fit-content;
            font-size: 0.85rem;
            &:first-of-type {
                font-size: 1.42rem; }
            @include respond-to('medium') {
                font-size: 1.12rem;
                margin: 0 10px;
                display: inline-block;
                width: auto;
                &:first-of-type {
                    font-size: 1.12rem; } }
            @include respond-to('large') {
                font-size: 1.82rem;
                &:first-of-type {
                    font-size: 1.82rem; } }
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $orange-text;
                @include respond-to('medium') {
                    bottom: 1px; } }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $orange-text;
                @include respond-to('medium') {
                    top: 1px; } } } }


    &__sub {
        font-size: 0.75rem;
        font-weight: 400;
        max-width: 900px;
        text-align: center;
        position: relative;
        margin-bottom: 20px;
        @extend .with-lines;
        &:before {
            display: none; }
        @include respond-to('medium') {
            margin-bottom: 100px; }
        @include respond-to('large') {
            font-size: 1rem; } }

    &__desc {
        line-height: 0.9rem;
        text-transform: uppercase;
        margin: 30px 0 10px;
        padding: 0 10px;
        max-width: 380px;
        br {
            display: none;
            @include respond-to('medium') {
                display: inline; } }
        @include respond-to('medium') {
            line-height: 1.4rem;
            max-width: none; }
        @include respond-to('large') {
            line-height: 1.6rem;
            margin: 40px 0 15px; }
        &__main {}

        &__sub {
            font-size: 0.68rem;
            @include respond-to('large') {
                font-size: 0.88rem; } } }

    &__desc2 {
        line-height: 0.6rem;
        max-width: 200px;
        margin: 0 auto;

        @include respond-to('medium') {
            line-height: 1.2rem;
            max-width: none; }

        &__main {
            font-size: 0.62rem;
            margin-bottom: 5px;
            display: block;
            @include respond-to('medium') {
                display: inline;
                margin-bottom: 0; }
            @include respond-to('large') {
                font-size: 0.66rem; } }

        &__sub {
            font-size: 0.7rem;
            @include respond-to('large') {
                font-size: 0.83rem; } } }

    &__link {
        margin-top: 30px;
        @include respond-to('medium') {
            margin-top: 15px; }
        @include respond-to('large') {
            margin-top: 20px; }
        &__icon {
            font-size: 0.45rem;
            margin-left: 8px;
            position: relative;
            top: -1px;
            @include respond-to('large') {
                font-size: 0.55rem; } }
        &__box {
            @extend .button--full-framed; } } }
