
.annotation-editor {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-top: 15px;
    background-color: rgba(33, 33, 33, 0.5);

    &__text__nav__link {
        @extend .text__nav__link;
        padding: 3px 10px;
        position: relative;
        cursor: pointer;

        &.disabled {
            pointer-events: none; }

        &.mode {
            &.mode-active {
                padding-left: 28px;
                border-color: #e19d4d;
                cursor: default;
                &:hover {
                    color: #fff; }
                > span {
                    &::before {
                        color: #e19d4d;
                        padding-right: 5px;
                        font-size: 10px;
                        position: absolute;
                        left: 8px;
                        top: 4px; } } }
            > span {
                &::before {
                    color: #e19d4d;
                    padding-right: 5px; } } } }

    &__split {
        @extend .container--full;

        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        @include respond-to('desktop') {
            flex-direction: row; }

        @include respond-to('large') {
            max-height: calc(100vh - 240px); } }

    &__transcription {
        position: relative;
        padding: 40px 0 30px 0;

        @include respond-to('desktop') {
            padding: 20px 5px 30px 0;
            flex: 1 1 50%;
            border-right: 1px solid #4C4C4C; }

        @include respond-to('large') {
            padding-right: 20px; }

        &__header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            &--button {
                @extend .text__button;
                padding: 5px 30px;
                font-weight: 600;
                font-size: 0.75rem;
                cursor: pointer; }

            &--title {
                padding: 7px 50px;
                background: #fff;
                border: solid 1px #CBB699;
                border-bottom: none;
                color: #414141;
                font-size: 0.75rem;
                font-weight: 600; } }

        &__content {
            background: #fff;
            border: solid 1px #cbb698;
            color: #202020;
            font-weight: 500;
            position: relative;
            padding: 20px 30px;
            display: grid;
            grid-template-columns: 1fr;
            max-height: 100%;
            overflow: auto;
            @include respond-to('large') {
                padding: 30px 60px; } }

        &__card {
            color: #676767;
            border-top: solid 1px #d7d7d7;
            font-size: 0.685rem;
            font-weight: 500;
            padding-top: 2px; }

        &__verses {}

        &__verse {
            display: grid;
            grid-template-columns: 30px 20px 1fr;
            grid-gap: 10px;
            @include respond-to('large') {
                grid-template-columns: 60px 35px 1fr; }

            &--no {
                color: #676767;
                font-size: 0.6875rem;
                font-weight: 500;
                padding: 2px;
                text-align: right;
                margin-top: 6px; }

            &--inner {
                font-family: 'PT SERIF', sans-serif;
                font-size: 1rem;
                color: #202020;
                font-weight: 500;
                padding-top: 3px;
                line-height: 1.5; } } }

    &__annotations {
        position: relative;
        min-height: 100px;
        padding: 20px 0 30px 0;
        @include respond-to("desktop") {
            padding-left: 5px;
            flex: 1 1 50%; }

        @include respond-to("large") {
            padding-left: 20px; }

        &__header {
            @extend .annotation-editor__transcription__header; }

        &__container {
            overflow-y: auto;
            max-height: 100%; } }

    &__annotation-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 42px;
        margin-top: 5px;
        box-shadow: inset 0 0 0 1px #CBB699;
        background: white;
        cursor: pointer;

        &.grey {
            background-color: #E3E3E3; }

        &:first-of-type {
            margin-top: 0; }

        // &:hover
        //     background: #F5E9DE
        //     box-shadow: inset 0 0 0 2px #E6AB64

        //     & .annotation-editor__annotation-item--button
        //         background: #E6AB64

        &.active {
            background: #F5E9DE;
            box-shadow: inset 0 0 0 2px #E6AB64; }

        &.semi-active {
            background: #faf3ec;
            box-shadow: inset 0 0 0 2px #ffefdc;

            // & .annotation-editor__annotation-item--button
 }            //     background: #E6AB64

        &--token {
            font-size: 1rem;
            font-family: 'PT SERIF', sans-serif;
            color: #1C1C1C;
            padding: 0 30px;
            width: 100%;
            font-weight: 700;
            margin-top: auto;
            margin-bottom: auto;
            @include respond-to('desktop') {
                font-size: 0.75rem; }

            @include respond-to('large') {
                font-size: 1rem; }

            > span {
                font-size: 0.875rem;
                font-weight: 500;
                color: #616161; } }

        &--buttons {
            height: 100%;
            display: flex; }

        &--button {
            border-left: 1px solid #CBB699;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: #1C1C1C;
            width: 80px;
            font-size: 0.75rem;
            font-weight: 600;
            cursor: pointer;
            @include respond-to('desktop') {
                font-size: 0.7rem;
                width: 60px; }
            @include respond-to('large') {
                font-size: 0.75rem;
                width: 80px; }

            &:hover {
                background-color: #E6AB64; } }

        &--hints {
            background-color: #E3E3E3;
            padding: 25px 30px 10px;
            border: 1px solid #CBB699;
            border-top: none;
            display: flex;
            flex-direction: column; }

        &--hint {
            font-family: 'PT SERIF', sans-serif;
            color: #1C1C1C;
            margin-bottom: 10px;
            font-size: 0.875rem;
            cursor: pointer;
            &:before {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #fff;
                border: 1px solid #CBB699; }

            &:hover {
                &::before {
                    background-color: #CBB699; } }

            &:last-child {
                margin-bottom: 0; }

            >.hint-lemma {
                font-weight: 700; }

            >.hint-id {
                font-weight: 500; }

            >.hint-class {
                color: #616161;
                font-weight: 500; } } } }

.annotation__shortcuts {
    position: absolute;
    top: 48px;
    right: 20px;
    background: #484036;
    border: 1px solid #DFDFDF;
    width: 386px;
    z-index: 10;
    font-size: 0.75rem;
    font-weight: 600;

    &--topbar {
        width: 100%;
        background: #fff;
        color: #616161;
        padding: 12px 21px;
        display: flex;
        justify-content: space-between; }

    &--close {
        cursor: pointer; }

    &--inner {
        padding: 20px; }

    &--item {
        display: grid;
        grid-template-columns: 50px 1fr;
        margin-bottom: 15px;

        &.last {
            grid-template-columns: 95px 1fr;
            margin-top: 15px;
            margin-bottom: 0;
            border-top: 1px solid #6F6153;
            padding-top: 15px;

            >.numbers {
                display: flex;
                align-items: baseline;
                gap: 10px; } } }

    &--icon {
        background: #484036;
        border: 1px solid #6F6153;
        padding: 5px;
        min-width: 25px;
        max-width: max-content;
        max-height: 25px;
        display: flex;
        justify-content: center; }

    &--description {
        padding-top: 5px;
        line-height: 1.5; } }

.subtoken {
    //padding: 0 1px
    padding: 0;
    cursor: pointer;
    &.active {
        background: #FFEACC; }

    &.selected {
        border: 1px solid #FFAB48;
        padding: 0;
        background: #FFEBCE; } }

.ann-modal {
    &__edit {
        max-width: 800px;
        width: calc(100vw - 80px); }

    &__leave-confirm {
        max-width: 650px;
        width: calc(100vw - 80px); }

    &__together {
        position: absolute;
        right: 0;
        background-color: #483f36;
        border: solid 1px #dfdfdf;
        width: calc(100% - 20px);
        // max-width: 600px

        &__tokens {
            font-family: 'PT SERIF', sans-serif;
            font-size: 1rem;
            text-align: left;
            margin-left: 16px; }

        &__button {
            display: flex;
            justify-content: flex-end;
            margin: 0 20px 20px; }

        &__message {
            font-size: 0.75rem;
            margin: 0 28px 20px;
            font-weight: 600;
            line-height: 1.3;
            color: #e19d4d; }

        &__hints {
            margin: 5px 30px 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #2E2721;

            & .hint-id {
                color: #fff; }
            & .hint-lemma {
                color: #fff; }
            & .hint-class {
                color: #fff; } }

        &__double {
            &__category {
                margin: 10px 25px 20px;
                color: #c9c9c9;
                font-family: 'PT SERIF', sans-serif;
                font-size: 0.875rem;
                border-bottom: 1px solid #2E2721;
                padding-bottom: 30px; } } }

    &__leave {
        font-size: 0.75rem;
        text-align: left;
        margin-top: -10px;
        > .ann-modal__leave__text {
            margin-bottom: 20px;
            > div {
                line-height: 1.2rem; } } }

    &__inner-loading {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 150;
        background: #483f36;
        display: flex; }

    &__header {
        @extend .modal--form__header;
        display: grid;
        grid-template-columns: 80px 1fr 80px;
        text-align: left;
        text-transform: none;
        color: #fff;
        font-weight: 600;
        padding-bottom: 10px;

        &--name {
            font-size: 0.75rem;
            font-weight: 400;
            display: flex;
            align-items: flex-end; }

        &--content {
            font-size: 0.875rem;
            display: flex;
            justify-content: center; } }

    &__edit-form {
        @extend .modal--form__content;
        padding: 10px;
        position: relative;

        &--content {
            min-height: 220px; }

        &__buttons {
            @extend .modal--form__buttons;
            margin-top: 0; }

        &__text-input {
            > .form__field__label {
                text-align: right;
                padding-right: 10px; }
            > .form__field__input {
                margin-top: 0; } }

        &__field {
            &__group {
                @extend .modal--form__field__group;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #2E2721;

                @include respond-to("medium") {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px 10px; }

                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none; }

                & .form__field--column {
                    display: flex;
                    justify-content: flex-start;
                    align-items: baseline; }

                & .form__field__label {
                    margin: 0;
                    flex: 1 1 100px; } } }

        &__error {
            text-align: left;
            margin-left: 20px;
            color: #e19d4d;
            font-size: 0.75rem; } }

    &__split__form {
        padding: 20px;
        display: flex;
        flex-direction: column;

        & .form__field__label {
            color: #fff;
            font-size: 0.75rem; }

        & .form__field__input {
            background-color: #2e2721;
            flex: 0 1 100%;
            font-size: 0.75rem;
            padding: 3px 8px;
            max-height: 30px; }

        & .form__field__error {
            color: #FF7B76;
            text-align: left;
            margin: 5px 0;
            font-size: 0.75rem; }

        & .modal--form__button {
            align-self: flex-end;
            margin-top: 20px; } } }

.ann-select {
    min-width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    border-color: #CE9756;
    > .form__field__label {
        margin-top: 20px;
        margin-bottom: 5px;
        padding-right: 10px;

        @include respond-to("medium") {
            margin: 0;
            flex: 1 1 100px; } }

    & .select--table__control {
        border-color: #685e55;
        height: calc(1rem + 8px);
        background: #2E2721;
        > .select--table__value-container {
            border-right: 1px solid #685e55; } }

    & .select--table__control--is-focused {
        border-color: #CE9756;
        > .select--table__value-container {
            border-right: 1px solid #CE9756; } }

    & .select--table__single-value {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: calc(1rem + 6px);
        padding: 0 3px; }

    & .select--table__menu {
        border-color: #CE9756;
        overflow-y: auto; }

    & .select--table__option {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px 8px; }

    & .select--table__input {
        font-size: 0.75rem;
        line-height: 1.75; }

    & .select--table__option--is-selected {
        &::before {
            content: none; } } }

.ann-select-keyboard {
    @extend .ann-select;
    & .select--table__option--is-selected {
        background: #1f1d1b; }

    & .select--table__option--is-focused {
        background: none; } }
