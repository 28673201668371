.header__top__mobile {
    @media (max-width: 767px) {
        display: none;
        &.opened {
            display: block; }
        .header {
            &__user-panel {
                width: 100%;
                text-align: left;
                .dropdown__box {
                    left: 0;
                    right: auto; } }
            &__top {
                &__buttons {
                    margin: 10px; }
                &__font {
                    margin-left: 0; }
                &__search {
                    margin: 10px;
                    &__form {
                        @include flex(1);
                        width: auto; } }
                &__nav {
                    position: fixed;
                    top: 0;
                    right: 0;
                    height: 100vh;
                    background: #1f1d1b;
                    z-index: 999;
                    width: 100%;
                    max-width: 400px;
                    border-left: solid 1px #6f6153;
                    padding-top: 20px;
                    @include flex-direction(column-reverse);
                    @include align-items(flex-end);
                    @include justify-content(flex-end);
                    &__links {
                        @include align-items(stretch);
                        &__link {
                            border: solid 1px transparent;
                            border-right: solid 1px #6f6153;
                            padding: 10px 20px;
                            margin: 0;
                            &:before {
                                display: none; }
                            &:hover, &:focus {
                                border: solid 1px $orange-text;
                                &:before {
                                    display: none; } }

                            // &:nth-of-type(2n)
                            //     border-right: none
                            &:after {
                                display: none; } } } } }

            &__part-container {
                margin-right: 0;
                margin-top: 0;
                margin-bottom: 0;
                padding: 0;
                width: 100%;
                border-bottom: solid 1px #3e362e;
                &:after {
                    display: none; } }

            &__main-nav {
                &__list {
                    width: 100%;
                    padding: 0 15px;
                    margin: 30px 0;
                    &-item {
                        margin: 4px 0;
                        width: 100%;
                        &__button {
                            font-size: 0.7rem; } } } } } } }
