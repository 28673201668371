.sitemap {
    @include flex(1);
    padding: 20px 0;
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        &-item {
            padding: 5px 0;
            &__link {
                @extend .link--light;
                &:after {
                    bottom: 0; }

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5; } } } } }
