.results {
    @extend .container--main;
    @include flexbox;
    @include align-items(flex-start);
    @include justify-content(center);
    @extend .text--regular;
    padding: 0;
    width: 100%;
    @include flex-direction(column);
    @include respond-to(large) {
        // height: calc(100vh - 130px)
        height: calc(100vh - 210px);
        @include flex-direction(row); }

    &--box-shadow {
        box-shadow: inset 0px -20px 20px -10px rgba(0, 0, 0, 0.4);
        transition: box-shadow 0.3s ease-in-out;
        &.hide-shadow {
            box-shadow: inset 0px -20px 20px -10px rgba(0, 0, 0, 0); } }

    &-page-sources {
        &__header {
            @extend .page__header;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            // padding: 0 10px
            @include respond-to(medium) {
                background: none;
                flex-direction: row;
                justify-content: space-between;
                align-items: center; } } }

    &-page {
        @include flex(1);
        &__top {
            border-bottom: solid 1px #322c26;
            @include box-shadow(-4px 10px 11px -5px rgba(0,0,0,0.44));
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            &--no-shadow {
                @extend .container--full;
                border-bottom: solid 1px #5b4f48;
                box-shadow: none;
                padding-bottom: 10px;
                // +respond-to("to-medium")
                //     margin: 0 10px
                //     width: auto
 } }                //     background: pink


        &__content {
            &__inner {
                @extend .page__content__inner;
                padding-bottom: 0;
                justify-content: flex-start; } }

        &__header {
            @extend .page__header;
            &__part {
                display: inline-block;
                @extend .text--regular;
                font-weight: 400;
                font-size: 0.66rem;
                margin-left: 10px;
                padding-left: 10px;
                border-left: solid 1px #5b4f48;
                color: #989898;
                text-transform: none;
                strong {
                    color: $orange-text; } } } }
    &__filters {
        width: 100%;
        @include respond-to(large) {
            width: auto;
            @include flex(0 0 300px); }
        &__form {
            border: solid 1px #5b4f48;
            padding: 0 10px 10px 10px;
            width: 100%;
            display: none;
            &.opened {
                display: inline-block; }

            @include respond-to(large) {
                display: inline-block; }

            &__list {
                padding: 0;
                list-style: none;
                &-item {
                    width: 100%;
                    margin: 5px 0; } }
            &__buttons {
                width: 100%;
                text-align: right;
                margin-top: 20px; }
            &__button {
                @extend .table__form__trigger;
                margin: 5px 0 5px 5px;
                padding-left: 27px;
                padding-right: 27px;
                font-size: 0.75rem;
                &.light {
                    border-color: #b5a799;
                    &:hover {
                        border-color: $orange-text; } }
                &.active {
                    border-color: #b5a799; } }
            &__field {
                @include flexbox;
                @include align-items(center);
                padding: 3px 0;

                &:hover {
                    >label {
                        color: $orange-text; }
                    >.results__filters__form__field__checkbox--visible {
                        border-color: $orange-text; } }

                &__checkbox--hidden {
                    @extend .checkbox--hidden; }
                &__checkbox--visible {
                    @extend .checkbox--visible;
                    border: solid 1px #5b4f48;
                    background: #352d28;
                    cursor: pointer;
                    margin-right: 10px;
                    &.checked {
                        color: #fff;
                        background: #bc772a; } }

                &__label {
                    @extend .text--regular;
                    font-weight: 500;
                    font-size: 0.7rem;
                    display: inline-block;
                    cursor: pointer;
                    // white-space: nowrap
                    line-height: 1rem;
                    strong {
                        font-weight: 700; }
                    &__name {
                        display: inline-block;
                        width: 35px; }
                    &__count {
                        display: inline-block; }

                    >.hits {
                        color: #9D9D9D; }

                    &--selected {
                        @extend .results__filters__form__field__label;
                        color: $orange-text; } } } }

        &__chosen {
            @extend .text--regular;
            font-weight: 500;
            font-size: 0.75rem;
            border: solid 1px #5b4f48;
            padding: 10px 15px;
            width: 100%;
            color: #989898;
            @include respond-to(large) {
                display: none; }
            &__list {
                list-style: none;
                margin: 0;
                padding: 0;
                &-item {
                    @extend .results__filters__form__list-item;
                    color: $orange-text; } }

            &--remove {
                color: #F5F7F6;
                margin-left: 7px;
                cursor: pointer;
                font-size: 0.6rem;
                &:hover {
                    color: $orange-text; } }

            &--code {
                font-weight: 700; }

            &__head {
                font-weight: 600;
                line-height: 18px;
                margin: 5px 0; } } }

    &__list {
        list-style: none;
        padding: 15px;
        margin: 0;
        border: solid 1px #5b4f48;

        &--sources {
            @extend .results__list;
            overflow-y: auto;
            @include respond-to(large) {
                max-height: calc(100vh - 300px); } }

        &-item {
            @extend .text--regular;
            font-weight: 500;
            font-size: 0.7rem;
            border: solid 1px #5b4f48;
            padding: 8px;
            margin-bottom: 10px;
            cursor: pointer;
            &:hover, &:focus {
                color: $orange-text;
                border: 1px solid $orange-text;
                background: #423b35;
                .results__list-item__link {
                    color: inherit;
                    text-decoration: none;
                    .results__list-item__context {
                        color: inherit; } } }

            &__link {
                @extend .text--regular;
                font-weight: 500;
                font-size: 0.75rem; }
            &__context {
                color: #989898;
                font-size: 0.7rem; } }

        &-container {
            overflow: auto;
            height: 100%;
            width: 100%;
            padding-bottom: 50px;
            min-height: 500px;
            @include respond-to(large) {
                margin-left: 20px;
                width: auto;
                @include flex(1); }

            &--sources {
                @extend .results__list-container;
                display: flex;
                flex-direction: column;
                padding-bottom: 0px; } } }

    &__header {
        @extend .text--regular;
        background: #fff;
        color: #414141;
        font-size: 0.7rem;
        font-weight: 600;
        margin-top: 20px;
        @include flexbox;
        position: relative;
        bottom: -1px;
        width: fit-content;
        border-top: solid 1px #ccb28f;
        @include respond-to(large) {
            border-top: none; }
        &__text {
            padding: 7px 15px;
            border: solid 1px #ccb28f;
            @include respond-to(large) {
                padding: 7px 35px; } }
        &-container {
            @include flexbox;
            @include justify-content(space-between);
            .pagination {
                margin-top: 8px; } } }

    &__no-results {
        @extend .text--regular;
        color: $orange-text;
        font-size: 0.7rem;
        font-weight: 600;
        margin-top: 20px; }

    &__trigger {
        background: #ebebeb;
        padding: 0 10px;
        @include respond-to(large) {
            display: none; }

        .results__trigger__img {
            &.hover {
                display: none; } }

        &:hover, &:focus {
            .results__trigger__img {
                display: none;
                &.hover {
                    display: block; } } }

        &:active {
            .results__trigger__img {
                display: block;
                &.hover {
                    display: none; } } }
        &__img {
            width: 8px;
            height: 8px; } }

    &__error {
        @extend .text--regular;
        font-weight: 500;
        padding: 50px 0;
        a {
            @extend .link--light;
            &:after {
                bottom: 0; } } }


    &__pagination {
        display: flex;
        justify-content: flex-end;
        margin: -30px 0 10px;
        align-items: center;
        z-index: 100; } }
