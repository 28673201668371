.react-autosuggest__container {
    position: relative; }

.react-autosuggest {
    &__input {
        color: #fff;
        font-weight: 600;
        width: 100%;
        z-index: 1;
        font-size: 0.8125rem;
        line-height: 1rem;
        padding: 15px;
        height: 38px;
        -webkit-flex: 1;
        flex: 1 1;
        background: #373028;
        border: solid 1px #685e55;
        transition: all 0.2s ease-in-out;

        // &--focused
        //     border-color: $orange-text
        //     outline: 0
        //     background-color: #1f1d1b
        &:focus, &:hover {
            border-color: $orange-text;
            outline: 0; }

        &:focus {
            background-color: #1f1d1b; }

        &:active {
            border-color: #fff;
            background-color: #1f1d1b; }

        &--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }

    &__suggestions-container {
        display: none;

        &--open {
            display: block;
            position: absolute;
            width: 100%;
            // border: solid 1px #685e55
            border: 1px solid $orange-text;
            background-color: #373028;
            font-weight: 300;
            font-size: 16px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            z-index: 2; } }

    &__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none; }

    &__suggestion {
        cursor: pointer;
        padding: 5px 15px;
        font-size: 0.6875rem;
        font-weight: 700;
        height: 26px;

        &--highlighted {
            background-color: #1f1d1b;
            color: #e19d4d;
            border-radius: 5px; } } }
