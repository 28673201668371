.breadcrumbs {
    @extend .container--full;
    @extend .text--regular;
    &__list {
        list-style: none;
        margin: 0 0 10px 0;
        padding: 0 0 7px 0;
        border-bottom: solid 1px #5b4f48;
        &__item {
            display: inline-block;
            font-weight: 400;
            color: #a5a5a5;
            font-size: 0.65rem;
            position: relative;
            padding-right: 11px;
            margin-right: 10px;
            &:after {
                content: '';
                background: #6f6153;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 2px;
                height: calc(100% - 4px);
                width: 1px; }
            &:last-of-type {
                &:after {
                    display: none; } }
            @include respond-to('large') {
                font-size: 0.7rem; } }
        &__link {
            @extend .text--regular;
            font-weight: 400;
            font-size: 0.65rem;
            transition: all 0.2s ease-in-out;
            @include respond-to('large') {
                font-size: 0.7rem; }
            &:hover, &:focus {
                color: $orange-text; }
            &:active {
                color: #fff; } } } }
