.sources {
    &__item {
        &--folder {
            @extend .manage-sources__item--folder;
            align-self: flex-start; } }

    &.modal {
        .modal {
            &__box {
                max-width: 350px; } } }

    &__results {
        @extend .results;
        @include respond-to(large) {
            height: calc(100vh - 210px);
            overflow: hidden; }


        &__item {
            display: flex;
            justify-content: space-between;
            // border: solid 1px #5b4f48
            margin: 10px 0;
            font-size: 0.7rem;
            font-weight: 500;

            &--added {
                @extend .sources__results__item;
                color: #e19d4d;
                .sources__results__item__btn {
                    background-image: url("../images/icons/A_iko_2_porownaie_odejmij.svg");
                    &:hover {
                        background-image: url("../images/icons/A_iko_2_porownaie_odejmij_hover.svg"); } }

                .sources__results__item__text {
                    color: #e19d4d; } }


            &__wrapper {
                padding: 8px;
                width: 100%;
                border: 1px solid #5b4f48;
                background: none;
                &--pointer {
                    @extend .sources__results__item__wrapper;
                    cursor: pointer;
                    &:hover {
                        border: 1px solid #ba894f;
                        background: #423b35;
                        >div {
                            color: #e19d4d; } } } }



            &__text {
                color: #989898;
                font-size: 0.625rem; }

            &__btn {
                min-width: 48px;
                border: solid 1px #5b4f48;
                border-left: solid 1px transparent;
                background-image: url("../images/icons/A_iko_2_porownanie.svg");
                background-position: calc(50% + 2px) calc(50% + 2px);
                background-size: 28px;
                background-repeat: no-repeat;
                cursor: pointer;
                &:hover {
                    border: 1px solid #ba894f;
                    background-image: url("../images/icons/A_iko_2_porownanie_hover.svg");
                    background-color: #423b35; } } }


        &__compare {
            &__modal {
                position: absolute;
                top: 0;
                right: 0;
                background: #483f36;
                width: 100%;
                max-width: 800px;
                min-height: 100px;
                box-shadow: 4px 0px 12px 4px rgba(0,0,0,0.44);
                // max-height: calc(100vh - 200px)
                z-index: 200;
                border: 1px solid #8f8b88;
                &--top {
                    min-height: 20px;
                    background: #dfdfdf;
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 10px;
                    color: #4d5155; }

                &--buttons {
                    width: 100%;
                    text-align: right;
                    margin-top: 0;
                    padding-right: 15px;
                    margin-bottom: 15px; } }

            &__button {
                align-self: flex-end;
                display: flex;
                font-size: 10px;
                align-items: center;
                font-weight: 500;
                padding: 3px 20px;
                margin: 10px 0 0 0;
                @include respond-to(medium) {
                    margin: 0; } } } }

    &__numberCircle {
        border-radius: 50%;
        width: 13px;
        height: 13px;
        padding: 1px;
        background: #bc772a;
        border: 0 solid #666;
        color: white;
        text-align: center;
        margin-left: 5px; }

    &__list {
        &--limited {
            @extend .results__list;
            border: none;
            overflow-y: auto;
            max-height: 550px; } } }

.no-border {
    border: none; }


