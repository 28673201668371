.form {
    &__buttons {
        text-align: right;
        margin-top: 20px;
        @include respond-to(small) {
            margin-top: 40px; } }

    &__button {
        @extend .text--regular;
        font-size: 0.66rem;
        font-weight: 600;
        border: solid 1px #b5a799;
        background: #483f36;
        margin-left: 7px;
        padding: 4px 25px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover, &:focus {
            border-color: $orange-text;
            color: $orange-text;
            text-decoration: none; }
        &:active {
            background: #1f1d1b;
            color: #fff; }

        &--clear {
            @extend .form__button;
            border-color: #685e55;
            &:hover, &:focus {
                border-color: #685e55;
                color: $orange-text; }
            &:active {
                border-color: #685e55;
                background: #1f1d1b;
                color: #fff; } } }

    &__field {
        &--column {
            text-align: left;
            &:not(:first-child) {
                margin-left: 10px; }
            > .form__field__input {
                width: 100%;
                margin-top: 8px; }
            > .form__field__label {
                flex: 1 1;
                text-align: left; } }

        text-align: center;
        @include flexbox;
        @include justify-content(center);
        flex-wrap: wrap;
        @include flex-direction(column);
        @include align-items(flex-start);
        @include respond-to(small) {
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(row);
            flex-wrap: nowrap; }

        &--filled {
            @include flex(1);
            @extend .text--regular;
            font-weight: 600;
            font-size: 0.66rem;
            text-align: left; }

        &__label {
            @include flex(1);
            text-align: left;
            @extend .text--regular;
            line-height: 1rem;
            font-weight: 600;
            color: #cacaca;
            margin-right: 5px;
            font-size: 0.66rem;
            width: 100%;
            @include respond-to(small) {
                width: auto;
                @include flex(0 0 100px);
                text-align: right; }

            &--right {
                @extend .form__field__label;
                text-align: left;
                margin-left: 5px;
                flex: 1 1;
                cursor: pointer; } }

        &__input {
            @extend .text--regular;
            font-weight: 600;
            width: 100%;
            z-index: 1;
            font-size: 0.66rem;
            line-height: 1rem;
            padding: 0 5px;
            @include flex(1);
            background: #373028;
            border: solid 1px #685e55;
            transition: all 0.2s ease-in-out;
            @include placeholder {
                color: #818181; }
            &:focus, &:hover {
                border-color: $orange-text;
                outline: 0; }
            &:focus {
                background-color: #1f1d1b;
                @include placeholder {
                    opacity: 0; } }
            &:active {
                border-color: #fff;
                background-color: #1f1d1b; }
            &:hover {
                @include placeholder {
                    color: #fff; }
                &:active {
                    @include placeholder {
                        color: #818181; } } }
            @include respond-to(small) {
                width: auto; } }

        &__select {
            @include flex(0 1 100%);
            width: 100%;
            @include respond-to(small) {
                width: auto; } }

        &__file {
            width: 100%;
            font-size: 0.66rem;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            height: 22px;
            max-width: 115px;
            width: 100%;
            &--visible {
                width: 100%;
                text-align: left;
                &__button {
                    @extend .form__button;
                    margin-left: 0; } }

            &__box {
                @include flex(1);
                width: 100%;
                @include align-self(flex-start);
                text-align: left;
                @include respond-to(small) {
                    width: auto; }
                &__file-name {
                    @extend .text--regular;
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 200px;
                    width: 100%;
                    margin-top: 5px;
                    font-size: 0.66rem; }
                &-inner {
                    position: relative;
                    cursor: pointer;
                    &:hover, &:focus {
                        .form__field__file--visible__button {
                            border-color: $orange-text;
                            color: $orange-text;
                            text-decoration: none; } }
                    &:active {
                        .form__field__file--visible__button {
                            background: #1f1d1b;
                            color: #fff; } } } } }

        &__error {
            @extend .text--regular;
            @include flex(1 1 100%);
            text-align: right;
            font-weight: 600;
            font-size: 0.66rem;
            line-height: 1.2rem;
            color: $orange-text; } } }
