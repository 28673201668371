.manage-texts {
    @extend .container--main;
    padding-top: 10px;
    @include flexbox;
    @include flex-direction(column);

    &__new-text {
        @extend .table__form__trigger;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        span {
            margin-left: 20px; } }

    &__top-bar {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-end); }

    &__list {
        @extend .table;
        list-style: none;
        padding: 5px;
        border: solid 1px #5b4f48;
        @include flex(1);
        @include respond-to('small') {
            padding: 20px; }

        &__title {
            @extend .table__title; }


        &__item {
            @extend .table__item--link;

            & > a {
                position: relative;
                &:hover, &:focus {
                    .manage-texts__list__item__field__img {
                        border-color: #b68245;
                        &:before, &:after {
                            background: #b68245; } } } }

            &__tooltip {
                @extend .table__item__tooltip;
                left: 100px;
                @include respond-to('small') {
                    left: 200px; } }

            &__field {
                @extend .table__item__field;
                font-weight: 600;

                &__img {
                    width: 30px;
                    padding-right: 10px;
                    border-right: solid 1px #5b4f48;
                    margin-right: 10px;
                    position: relative;
                    display: none;
                    &:before,
                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        width: 1px;
                        height: 5px;
                        right: -1px;
                        background: #5b4f48; }
                    &:before {
                        top: -5px; }
                    &:after {
                        bottom: -5px; }
                    @include respond-to('large') {
                        display: inline-block; } }

                &:before {
                    @include respond-to('small') {
                        @include flex(0 0 180px); } }

                &--name {
                    @extend .manage-texts__list__item__field;
                    @include flex(1);
                    text-transform: uppercase;
                    &:before {
                        text-transform: none; }
                    @include respond-to('large') {
                      @include flex(0 1 400px); } } } }

        &__header {
            @extend .table__header;

            &__field {
                @extend .table__header__field;
                &--name {
                    @extend .manage-texts__list__header__field;
                    @include flex(0 1 400px); } } } }

    &.modal {
        .modal {
            &__box {
                max-width: 550px; } } } }




