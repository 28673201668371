@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.eot?81973150');
  src: url('../fonts/icons.eot?81973150#iefix') format("embedded-opentype"), url('../fonts/icons.woff2?81973150') format("woff2"), url('../fonts/icons.woff?81973150') format("woff"), url('../fonts/icons.ttf?81973150') format("truetype"), url('../fonts/icons.svg?81973150#icons') format("svg");
  font-weight: normal;
  font-style: normal; }


/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
 *@media screen and (-webkit-min-device-pixel-ratio:0) {
 *  @font-face {
 *    font-family: 'icons';
 *    src: url('../font/icons.svg?81973150#icons') format('svg');
 *  }
 *} */

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-no-data:before {
  content: '\e800'; }

/* '' */

.icon-cookies:before {
  content: '\e803'; }

/* '' */

.icon-accept:before {
  content: '\e80a'; }

/* '' */

.icon-edit:before {
  content: '\e80b'; }

/* '' */

.icon-en:before {
  content: '\e80e'; }

/* '' */

.icon-contrast:before {
  content: '\e810'; }

/* '' */

.icon-contrast-hover:before {
  content: '\e813'; }

/* '' */

.icon-search:before {
  content: '\e814'; }

/* '' */

.icon-new-window:before {
  content: '\e815'; }

/* '' */

.icon-pl:before {
  content: '\e819'; }

/* '' */

.icon-compare-remove:before {
  content: '\e81a'; }

/* '' */

.icon-compare-add:before {
  content: '\e81c'; }

/* '' */

.icon-move:before {
  content: '\e81d'; }

/* '' */

.icon-question:before {
  content: '\e81e'; }

/* '' */

.icon-arrow-right:before {
  content: '\e824'; }

/* '' */

.icon-close:before {
  content: '\e829'; }

/* '' */

.icon-light:before {
  content: '\e82a'; }

/* '' */

.icon-font:before {
  content: '\e82c'; }

/* '' */

.icon-link:before {
  content: '\e82d'; }

/* '' */

.icon-arrow-dbl-right:before {
    content: '\e80e'; }

.icon-arrow-dbl-left:before {
    content: '\e80d'; }

.icon-check-dbl:before {
    content: '\e808'; }

.icon-chevron-up:before {
    content: '\e821'; }
