.header {
    @extend .container--full;
    padding-top: 10px;

    &__top {
        @include flexbox;
        @include align-items(center);
        margin: 0;
        @include respond-to('medium') {
            margin: 10px 0; }
        @include respond-to('large') {
            &.subpage {
                margin: 0;
                @include align-items(flex-start); } }
        &.subpage {
            .header__logo-section {
                @include respond-to('large') {
                    margin-top: 15px; } }
            .header__part-container {
                margin-top: 0;
                margin-bottom: 0;
                &:last-of-type {
                    padding-right: 0;
                    .header__top__lang {
                        margin-right: 0; } } } }

        &--small {
            @include respond-to('medium') {
                display: none; } }
        &__nav {
            @include flexbox;
            @include justify-content(flex-end);
            @include align-items(center);
            text-align: right;
            &__links {
                @include flexbox;
                &__link {
                    @extend .link--underlined;
                    margin: 0 2px;
                    font-size: 0.63rem;
                    text-align: center;
                    white-space: nowrap;
                    &:after {
                        bottom: -1px; }
                    @include respond-to('large') {
                        margin: 0 7px;
                        font-size: 0.73rem; } } } }

        &__buttons {
            @include flexbox;
            @include align-items(center); }

        &__button {
            @extend .with-icon;
            height: 16px;
            width: 16px;
            padding: 0;
            margin: 0 7px;
            @include respond-to('large') {
                height: 18px;
                width: 18px;
                margin: 0 10px; } }

        &__font,
        &__contrast,
        &__lang {
            @extend .header__top__button; }

        &__font {
            margin-left: 12px; }

        &__lang {
            @include respond-to('medium') {
                margin-right: 10px; }
            @include respond-to('large') {
                margin-right: 17px; } }

        &__menu-toggle {
            padding: 0;
            height: 40px;
            width: 40px;
            background: #fff;
            border-radius: 50%;
            text-align: center;
            margin: 0 7px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            top: 10px;
            right: 0;
            position: fixed;
            z-index: 9999;
            @include respond-to('medium') {
                display: none; }
            span {
                background: #342d28;
                display: block;
                margin: 2px auto;
                height: 3px;
                width: 15px; }
            &:hover, &:focus {
                background: #342d28;
                border: solid 1px $orange-text;
                span {
                    background: $orange-text; } }
            &:active {
                background: #000;
                border: solid 1px #fff;
                span {
                    background: #fff; } } }

        &__search {
            @include flexbox;
            margin: 0 17px 0 12px;
            width: 100%;
            @include respond-to('medium') {
                display: none; }
            @media (min-width: 768px) and (max-width: 1365px) {
                &.opened {
                    @include flexbox;
                    position: absolute;
                    top: 80px;
                    left: 0;
                    width: 100vw;
                    margin: 0;
                    padding: 15px;
                    z-index: 2;
                    background: #1f1d1b;
                    @include justify-content(center);
                    border-top: solid 1px #6f6153;
                    border-bottom: solid 1px #6f6153;
                    .header__top__search__form {
                        @include flex(0 1 700px); } } }
            @include respond-to('large') {
                @include flexbox; }
            &__form {
                @include flexbox;
                width: 300px;
                @include respond-to('large') {
                    width: 350px; } }
            &__toggle {
                display: none;
                background: #fff;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                color: #3d3733;
                padding: 0;
                font-size: 0.5rem;
                margin: 0 10px 0 12px;
                border: solid 1px #fff;
                z-index: 1;
                &:hover, &:focus {
                    background: #1f1d1b;
                    border-color: #b68245;
                    color: #b68245; }
                &:active {
                    color: #fff;
                    border-color: #fff; }
                @include respond-to('medium') {
                    display: block; }
                @include respond-to('large') {
                    display: none; } } }

        &__form {
            &__buttons {
                @include flexbox; }
            &__button {
                @extend .button--full-framed;
                border: solid 1px #685e55;
                background: #2f2924;
                padding: 7px;
                z-index: 1;
                border-left: none;
                height: 30px; }

            &__field {
                @include flex(1); } } }

    &__form {
        &__field {
            @extend .form__field;
            &__label {
                @extend .form__field__label;
                @extend .hidden; }

            &__input {
                @extend .form__field__input;
                background: #2f2924;
                padding: 5px;
                min-height: 30px; }

            &__error {
                @extend .form__field__error; } } }

    &__main-nav {
        margin-top: 20px;
        text-align: center;
        display: none;
        @include respond-to('small') {
            margin-top: 50px; }
        @include respond-to('medium') {
            display: block;
            margin-top: 100px; }
        @include respond-to('large') {
            margin-top: 50px; }
        &--mobile {
            text-align: center;
            width: 100%;
            @include respond-to('medium') {
                display: none; } }
        &__list {
            list-style: none;
            padding: 0;
            margin-top: 8px;
            margin-bottom: 0;

            &-item {
                display: inline-block;
                margin: 5px 7px;
                width: 142px;
                @include respond-to('large') {
                    margin: 5px 17px;
                    width: 200px; }
                &__button {
                    @extend .button--framed;
                    position: relative;
                    .header__main-nav__active-bar {
                        display: none;
                        &:after, &:before {
                            display: none; } }
                    &.active {
                        color: $orange-text;
                        background-color: #2f2924;
                        .header__main-nav__active-bar {
                            display: inline-block;
                            position: absolute;
                            bottom: 0;
                            top: initial;
                            left: 7px;
                            height: 2px;
                            width: calc(100% - 14px);
                            background: $orange-text; } } } } }

        &.subpage {
            margin-top: 0;
            @include respond-to('large') {
                margin-top: -40px;
                text-align: right; }

            .header__main-nav {
                &__list {
                    &-item {
                        text-align: center;
                        margin-left: 3px;
                        margin-right: 3px;
                        width: 142px;
                        @include respond-to('large') {
                            width: 200px; }
                        &__button {
                            font-size: 0.66rem;
                            padding: 7px;
                            border-color: #6f6153;
                            &, span {
                                &:before,
                                &:after {
                                    background: #6f6153;
                                    height: 5px; } }
                            &:hover, &:focus {
                                border-color: $orange-text;
                                // background: #1f1d1b
                                &, span {
                                    &:before,
                                    &:after {
                                        background: $orange-text;
                                        height: 100%; } } }
                            &:active {
                                border-color: #fff;
                                &, span {
                                    &:before,
                                    &:after {
                                        background: #fff; } } } } } } } } }

    &__logo-section {
        @include flexbox;
        margin-right: auto;
        &__main {
            &-logo {
                position: relative;
                width: 150px;
                @include respond-to('small') {
                    width: 200px; } }
            &-link {
                position: relative;
                padding-right: 5px;
                &--underlined {
                    @extend .link--underlined;
                    &:after {
                        display: none; }
                    &:hover, &:focus, &:active {
                        &:after {
                            display: block; } } }
                &:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: calc(100% - 10px);
                    top: 2px;
                    right: 0;
                    background: #6f6153; } } }
        &__sup {
            &-link {
                margin-left: 5px; }
            &-logo {
                position: relative;
                width: 30px;
                @include respond-to('small') {
                    width: 39px; } } } }

    &__part-container {
        @include flexbox;
        @include align-items(center);
        margin: 5px 10px 5px 0;
        @include respond-to('large') {
            margin: 5px 20px 5px 0;
            padding-right: 15px; }
        &::after {
            content: "";
            display: inline-block;
            position: relative;
            margin-left: 10px;
            right: 0;
            height: 10px;
            width: 1px;
            background: $orange-text;
            @include respond-to('large') {
                margin-left: 25px; } }
        &:last-of-type {
            margin-right: 0;
            &:after {
                display: none; } } }

    &__user-panel {
        .dropdown__button {
            font-size: 0.73rem; }
        &__inner {
            text-align: left; }
        &__list {
            list-style: none;
            margin: 0;
            padding: 5px 60px 5px 10px;
            &-item {
                padding: 2px 5px; } }
        &__link {
            @extend .link--light;
            font-size: 0.66rem;
            font-weight: 700;
            padding: 0 5px 0 14px;
            white-space: nowrap;
            line-height: 1.5rem;
            position: relative;
            transition: color 0.2s ease-in-out;
            &:after {
                display: none; }
            &.active {
                color: $orange-text;
                pointer-events: none; }
            .icon {
                margin-right: 5px;
                font-size: 0.45rem;
                position: absolute;
                left: 0;
                top: 0; } }
        &__buttons {
            background: #2d2721;
            border-top: solid 1px #685e55;
            padding: 5px;
            text-align: right; }
        &__button {
            @extend .button--full-framed;
            border-color: #fff;
            padding: 7px 20px;
            &:hover, &:focus {
                border-color: $orange-text;
                // background: #1f1d1b
                color: $orange-text; }
            &:active {
                color: #fff;
                border-color: #fff;
                background: none;
 } } } }                // background: #1f1d1b
