.page {
    @include flex(1 0 auto);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(stretch);
    z-index: 1;
    margin-top: 10px;

    &__dynamic {
        &--wrapper {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column; } }

    &--alt {
        // height: 100%
        // max-height: 100%

        @extend .page;
        .page {
            &__content {
                border-top: solid 1px #6f6153;
                background-color: rgba(33, 33, 33, 0.5);
                &__inner {
                    border-left: solid 1px #6f6153;
                    border-right: solid 1px #6f6153;
                    border-bottom: solid 1px #6f6153;
                    margin-bottom: 30px; }

                &--scrollable {
                    justify-content: flex-start;
                    height: 1px;
                    max-height: 100%;
                    overflow: auto; } } } }

    &__top {
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        &--shadow {
            padding: 0 10px;
            position: relative;
            background: #413a35;
            border-top: solid 1px #231e1c;
            border-bottom: solid 1px #231e1c;
            @include box-shadow(-4px 10px 11px -5px rgba(0,0,0,0.44)); } }

    &__content {
        @include flexbox;
        @include align-items(stretch);
        @include justify-content(center);
        @include flex-direction(column);
        @include flex(1 0 auto);
        padding-left: 10px;
        padding-right: 10px;
        position: relative;

        &__inner {
            @extend .container--full;
            @extend .container--main;
            @include flexbox;
            @include align-items(stretch);
            @include justify-content(center);
            @include flex-direction(column);
            @extend .text--regular; } }

    &__header {
        color: $orange-text;
        font-size: 0.7rem;
        @extend .container--full;
        font-weight: 600;
        @include respond-to('large') {
            font-size: 0.85rem; }

        &__comment {
            color: #A6A4A5;
            font-size: 0.7rem;
            @extend .container--full;
            font-weight: 400;
            margin-top: 5px;
            margin-bottom: 5px;
            @include respond-to('large') {
                font-size: 0.75rem; } } }




    &__inner {
        text-align: center; }
    &__loader {
        height: 80px;
        &--full {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.1);
            z-index: 2;
            @include flexbox;
            @include align-items(center);
            @include justify-content(center); } }

    &--modal {
        @extend .page__content;
        padding-top: 20px; } }
