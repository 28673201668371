.text {
    @extend .container--main;
    @include flexbox;
    @include align-items(stretch);
    @include justify-content(center);
    @include flex-direction(column);
    @extend .text--regular;
    padding: 0;

    .page {
        &__top {
            padding-top: 10px;
            background: #413a35;
            border-top: solid 1px #231e1c;
            border-bottom: solid 1px #231e1c; }

        &__content {
            border-top: none;
            &__inner {
                padding-bottom: 0;
                border: none; } } }

    &__themes-modal {
        position: absolute;
        top: 30px;
        right: 0;
        background: #483f36;
        width: 400px;
        max-width: calc(100% - 50px);
        min-height: 100px;
        z-index: 200;
        border: 2px solid #827d77;
        &__inner {
            padding: 10px;
            height: 300px;
            overflow: auto; }
        &__item {
            margin-bottom: 10px; }
        &__item-name {
            text-transform: none;
            font-size: 0.7rem;
            font-weight: 500;
            color: #a5a5a5; }
        &__item-button {
            display: block;
            text-transform: none;
            cursor: pointer;
            font-size: 0.7rem;
            margin: 5px 0;
            font-weight: 500;
            color: white;
            &--text {
                display: inline-block;
                border: 1px solid #6f6153;
                padding: 2px;
                margin: 0; }

            &:hover {
                color: $orange-text;
                text-decoration: none; } } }

    &__inner {
        @include flexbox;
        @include flex(1);
        @include align-items(center);
        @include flex-direction(column); }

    &__tabs {
        width: 100%;

        &__list {
            @extend .text-tabs__tabs__list;
            margin: 0;

            &-item {
                @extend .text-tabs__tabs__list-item;
                &:first-child {
                    > .text-tabs__tabs__list-item__link {
                        border-left-color: #231e1c;
                        &:hover {
                            border-left-color: #e19d4d; } } }

                &__link {
                    @extend .text-tabs__tabs__list-item__link;
                    border-color: #231e1c;
                    border-bottom-color: transparent;
                    border-left-color: transparent; } } } }

    &__split {

        &__modal {
            background: #ffffff;
            border: 1px solid #dbdbdb;
            box-shadow: 0px 3px 6px #00000029;
            width: 250px;
            min-height: 70px;
            position: absolute;
            z-index: 220;
            font-family: 'Open Sans', sans-serif;

            &--drag-handler {
                width: 100%;
                height: 23px;
                background: #DBDBDB; }

            &--close {
                @extend .modal__close;
                position: absolute;
                right: 3px;
                top: 5px; }

            &--items {
                padding: 12px; }

            &--item {
                font-size: 0.75rem;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0; } }

            &--line {
                border-bottom: 1px dashed #DBDBDB;
                margin-bottom: 12px; } }

        &__part {
            position: relative;
            @include flexbox;
            @include flex-direction(column);
            min-height: 37px;
            top: -3px;
            left: -1px;
            width: calc(100% + 2px);

            &--source-text {
                padding: 1rem 35px;
                line-height: 1.3rem;
                max-height: 223px; }

            &__header {
                @include flexbox;
                @include justify-content(space-between);
                @include align-items(stretch);

                &__text {
                    padding: 10px 0; }

                &__button {
                    @extend .text--regular;
                    background: #ccb28f;
                    cursor: pointer;
                    border-top: solid 1px $l-bronze;
                    border-bottom: solid 1px $l-bronze;
                    padding: 0 0 0 40px;
                    color: #1e1a15;
                    width: 100%;

                    .text__split__part__header__icon {
                        &.hover {
                            display: none; } }

                    &:hover, &:focus {
                        .text__split__part__header__icon {
                            display: none;
                            &.hover {
                                display: block; } } }

                    &:active {
                        .text__split__part__header__icon {
                            display: block;
                            &.hover {
                                display: none; } } }

                    &:hover, &:focus {
                        background: #fff;
                        border-color: #e6973b;
                        color: #d58a33; }

                    &:active {
                        color: #1e1a15;
                        background: #fff;
                        border-color: $l-bronze; } }

                &__icon {
                    height: 0.4rem;
                    position: relative;

                    &-container {
                        border-right: solid 1px $l-bronze;
                        padding: 16px 15px; }
                    @include flexbox;
                    @include align-items(center); } }

            &__inner {
                padding: 5px;
                @include flex(1);
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                display: none;

                &.visible {
                    display: block;
                    font-family: 'PT SERIF', sans-serif;
                    font-size: 0.9375rem; }

                @include respond-to(large) {
                    display: block; }
                &-container {
                    @include flexbox;
                    @include flex(1);
                    overflow: hidden; } } }

        &__list {
            margin: 0 auto;
            list-style: none;
            padding: 0;
            @include flex(1);
            &__item {
                @include flexbox; }

            &--source {
                padding: 1rem 40px;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                width: 100%; } }

        &__gutter {
            &__icon {
                font-size: 0.8rem; }
            &__inner {
                cursor: pointer;
                z-index: 2;
                // display: inline-block
                // padding: 4px 2px
                background: #fff;
                border: solid 1px #cccccc;
                position: relative;
                top: -15px;
                left: 10px;
                height: 25px;
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover, &:active {
                    border-color: #e6973b; } } } }

    &__content {
        @extend .text-tabs__content;
        padding: 10px 0 0 0;
        @include flexbox;
        height: 100%;
        @include flex(1);
        @include flex-direction(column);

        &__inner {
            width: 100%;
            @include flexbox;
            @include flex-direction(column);
            height: 100%;
            @include flex(1); }

        &-container {
            @include flexbox; }

        &__header {
            @include flexbox;
            @include justify-content(space-between);
            display: none;
            position: relative;
            @include respond-to(large) {
                @include flexbox; }
            &--mobile {
                @extend .text__content__header;
                @include flexbox;
                @include respond-to(large) {
                    display: none; } }

            &__inner {
                @extend .text--regular;
                padding: 7px 20px;
                display: inline-block;
                font-weight: 600;
                font-size: 0.7rem;
                border-bottom: none;
                margin-right: 5px;
                background: #413a35;
                border: solid 1px #65594d;
                color: #fff;

                &.active {
                    background: #fff;
                    border: solid 1px #cbb698;
                    color: #414141;
                    pointer-events: none; }

                @include respond-to(large) {
                    padding: 7px 50px;
                    background: #fff;
                    border: solid 1px #cbb698;
                    color: #414141; }

                &__button {
                    @extend .button--full-framed--reversed;
                    text-transform: none;
                    margin-left: 5px;
                    padding: 3px 40px 3px 7px;
                    position: relative;
                    border-color: #6f6153;

                    &[disabled] {
                        opacity: 0.5;
                        pointer-events: none; }

                    .text__nav__link__img {
                        &.hover {
                            display: none; } }

                    &:hover, &:focus {
                        .text__nav__link__img {
                            display: none;
                            &.hover {
                                display: block; } } }

                    &:active {
                        .text__nav__link__img {
                            display: block;
                            &.hover {
                                display: none; } } } } }

            &__part {
                @include flex(1);
                @include flexbox;
                @include align-items(center);
                &:nth-of-type(2n + 1) {
                    @include justify-content(flex-start);
                    padding-right: 10px; }
                @include respond-to(large) {
                    @include justify-content(space-between);
                    &:nth-of-type(2n + 1) {
                        @include justify-content(flex-start);
                        margin-right: 6px;
                        padding-right: 8px;
                        // border-right: solid 1px #6a5d50
                        &:last-child {
                            border-right: none; } } } }

            &__part-src {
                flex: 1 1 50%;
                @include flexbox;
                @include justify-content(space-between);
                padding-left: 6px;
                &:nth-of-type(2n + 1) {
                    padding-right: 10px;
                    padding-left: 0;
                    border-right: solid 1px #6a5d50; } } }

        &__split {
            @extend .text--regular;
            background: #fff;
            border: solid 1px #cbb698;
            color: #202020;
            font-weight: 500;
            position: relative;
            min-height: 100%;
            @include flex(1);
            display: none;
            @include flex-direction(column);
            @include box-shadow(-4px 10px 11px -8px rgba(0,0,0,0.44));

            &.visible {
                @include flexbox; }
            @include respond-to(large) {
                @include flexbox; }
            // &.splited
            //     +respond-to(large)
            //         &:before
            //             content: ''
            //             background: #6a5d50
            //             height: 100%
            //             display: inline-block
            //             width: 1px
            //             position: absolute
            //             left: 50%
            //             z-index: 2

            &__no-data {
                @include flex(1);
                &__inner {
                    padding: 15px;
                    @extend .text--regular;
                    font-weight: 500; } }

            &-container {
                @include flexbox;
                @include flex(1 0 auto);
                height: 1px;
                &__inner {
                    @include flex(1);
                    overflow: auto;
                    display: none;
                    position: relative;
                    z-index: 2;
                    min-height: 100%;
                    padding-bottom: 30px;
                    &.visible {
                        display: block; }
                    @include respond-to(large) {
                        display: block;
                        padding-left: 6px;
                        margin-left: 6px;
                        border-left: solid 1px #6a5d50; } } }

            &.no-notes {
                .text__split__gutter {
                    pointer-events: none;
                    &__icon {
                        opacity: 0.5; } } }

            &__group {
                margin-top: 7px;
                display: none;
                @include flex-direction(column);
                &.visible {
                    @include flexbox; }
                @include respond-to(large) {
                    @include flexbox; }
                &.opened {
                    height: 600px;
                    .text__content__split {
                        @include flex(1);
                        min-height: 1px; }
                    &.short {
                        height: 300px; } }
                &__header {
                    &__button {
                        @extend .text__split__part__header__button;
                        background: #e3e3e3;
                        &.opened {
                            background: #fff;
                            border-color: #e6973b;
                            color: #d58a33; } } } } } }

    &__verse {
        @extend .text--regular;
        line-height: 1.2rem;
        max-width: 700px;
        @include flex(1);
        margin: 0 auto;
        padding: 0 5px;
        display: none;
        &.visible {
            @include flexbox; }
        @include respond-to(small) {
            padding: 0 15px; }
        @include respond-to(large) {
            @include flexbox; }
        @include respond-to(large) {
            padding: 0 50px; }

        &__card {
            @include flex(0 1 20px);
            @include align-content(flex-start);
            color: #676767;
            border-top: solid 1px #d7d7d7;
            font-size: 0.685rem;
            font-weight: 500;
            padding-top: 2px;
            @include respond-to(small) {
                @include flex(0 1 40px); }
            @include respond-to(large) {
                @include flex(0 1 70px); }

            &--empty {
                @include flex(0 1 20px);
                @include respond-to(small) {
                    @include flex(0 1 40px); }
                @include respond-to(large) {
                    @include flex(0 1 70px); } } }

        &__verse-no {
            @include flex(0 1 40px);
            @include align-content(flex-start);
            color: #676767;
            font-size: 0.685rem;
            font-weight: 500;
            padding: 2px;
            text-align: center;
            @include respond-to(large) {
                @include flex(0 1 50px); } }

        &__verse-no-highlight {
            border: 1px solid transparent;
            background: transparent;
            line-height: 18px;
            height: 18px;
            flex: 0 1 30px;
            margin: 4px 10px 0 5px;
            color: #676767;
            font-size: 0.685rem;
            font-weight: 500;
            padding: 2px;
            text-align: center;
            @include respond-to(large) {
                margin: 4px 10px 0; } }

        &__verse {
            font-family: 'PT SERIF', sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            color: #202020;
            font-weight: 500;
            padding-top: 3px;
            @include flex(1);
            span {
                line-height: 1; }

            &--source {
                color: #202020;
                font-weight: 500;
                padding-bottom: 5px;
                font-size: 0.8125rem; } } }

    &__breadcrumbs {
        @include flexbox;
        border-bottom: solid 1px #231e1c;
        @extend .container--full;
        padding: 7px 0;

        .breadcrumbs {
            max-width: none;
            padding: 0 5px;
            &__list {
                border: none;
                margin: 0;
                padding: 0; } } }

    &__button {
        @extend .button--full-framed--reversed;
        border-color: #6f6153;
        padding: 2px 15px;
        &:active {
            background: #413a35;
            border-color: #fff; }
        &.disabled {
            opacity: 0.5; } }

    &__back {
        @extend .text__button;
        margin-right: 20px;
        position: relative;
        padding-left: 40px;

        &__icon {
            display: inline-block;
            position: absolute;
            left: 5px;
            top: 5px;
            height: 0.5rem; }

        .text__back__icon {
            &.hover {
                display: none; } }

        &:hover, &:focus {
            .text__back__icon {
                display: none;
                &.hover {
                    display: block; } } }

        &:active {
            .text__back__icon {
                display: block;
                &.hover {
                    display: none; } } } }

    &__nav {
        position: relative;
        @include flexbox;
        padding: 7px 0;
        @include justify-content(space-between);
        align-items: center;

        &__links {
            &__header {
                @extend .text--regular;
                display: inline-block;
                text-transform: none;
                font-size: 0.7rem;
                font-weight: 500;
                color: #a5a5a5; } }

        &__link {
            @extend .text__button;
            text-transform: none;
            margin-left: 5px;
            padding: 3px 40px 3px 7px;
            position: relative;

            .text__nav__link__img {
                &.hover {
                    display: none; } }

            &:hover, &:focus {
                .text__nav__link__img {
                    display: none;
                    &.hover {
                        display: block; } } }

            &:active {
                .text__nav__link__img {
                    display: block;
                    &.hover {
                        display: none; } } }

            &__img {
                display: inline-block;
                height: 0.5rem;
                position: absolute;
                right: 7px;
                top: 5px; } } }

    &__checkbox {
        @include flexbox;
        @include flex(1);
        @include justify-content(flex-end);
        &:hover:enabled , &:focus:enabled {
            .text__checkbox--visible {
                border-color: $orange-text;
                &.checked {
                    background: $orange-text;
                    color: #fff;
                    border-color: $l-bronze; } }

            .text__checkbox__label__link {
                color: $orange-text; } }

        &--hidden {
            @extend .table__form__field__checkbox--hidden;
            display: inline-block; }
        &--visible {
            @extend .table__form__field__checkbox--visible;
            display: inline-block;
            background: #413a35;
            border: solid 1px #6a5d50;
            flex-shrink: 0;
            &.checked {
                color: #fff; } }
        &__label {
            display: inline-block;
            margin-left: 5px;
            &__link {
                @extend .text--regular;
                color: #fff;
                font-weight: 600;
                font-size: 0.7rem;
                transition: all 0.2s ease-in-out;

                &[disabled] {
                    pointer-events: none;
                    opacity: 0.5; }

                &:hover, &:focus {
                    text-decoration: none;
                    color: #e19d4d; } } } } }

.split-container--hide {
    display: none; }

.container-left {
    margin-left: 0;
    padding-left: 0;
    border-left: none; }

.text__button--eye {
    background: #e3e3e3;
    border-top: solid 1px $l-bronze;
    border-bottom: solid 1px $l-bronze;
    border-left: solid 1px #1e1a15;
    padding: 0;
    color: #1e1a15;
    width: 40px;
    align-items: center;
    cursor: pointer;
    &:hover {
        background: #fff;
        border-color: #e6973b;
        color: #d58a33; } }

.text__button--eye-rem {
    @extend .text__split__part__header__icon-container;
    border-right: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > .text__split__part__header__icon {
        &.hover {
            display: none; } }
    &:hover, &:focus {
        > .text__split__part__header__icon {
            display: none;
            &.hover {
                display: block; } } } }

.verse-no--highlighted {
    border: 1px solid #ffab48;
    background: #fdeedb; }

.text__content__split--hidden {
    display: none !important; }

.splited {
    @include respond-to(large) {
        &:before {
            content: '';
            background: #464544;
            // background: #6a5d50
            height: 100%;
            display: inline-block;
            width: 2px;
            position: absolute;
            left: 50%;
            z-index: 2; } } }
