.clearfix {
  clear: both; }

.hidden {
  display: none; }

.offscreen {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px) /* IE 6/7 */;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.onscreen {
  clip-path: none;
  clip: auto;
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  white-space: normal;
  width: auto; }

.close {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  overflow: hidden;
  padding: 0;
  &:hover {}
  &:before, &:after {
    background: #9b6b1e; }
  &:before, &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -0.5px;
    background: #383236; }
  &:before {
    @include transform-rotate(45deg); }
  &:after {
    @include transform-rotate(-45deg); } }

.external-link {
  @extend .text--regular;
  position: absolute;
  color: #878787;
  font-size: 0.5rem;
  top: -2px;
  right: -12px; }

.icon {
  height: 100%; }


.with-icon {
  position: relative;
  .regular, .hover, .active, .checked, .checked_2 {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    height: 100%; }
  .hover, .active {
    opacity: 0; }
  &:hover, &:focus {
    .hover {
      opacity: 1; }
    .regular {
      opacity: 0; } }
  &:active {
    .active {
      opacity: 1; }
    .regular {
      opacity: 0; } } }

.with-lines {
  &::after {
      content: "";
      position: absolute;
      bottom: -40px;
      left: 50%;
      width: 1px;
      height: 25px;
      background: rgba(206, 151, 86, 0.6);
      display: none;
      @include respond-to('medium') {
          display: block; }
      @include respond-to('large') {
          height: 35px;
          bottom: -55px; } }
  &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      width: 1px;
      height: 25px;
      background: rgba(206, 151, 86, 0.6);
      display: none;
      @include respond-to('medium') {
          display: block; }
      @include respond-to('large') {
          top: -35px;
          height: 35px; } } }
