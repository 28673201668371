.pagination {
    @include flexbox;
    @include align-items(center);

    &__pages {
        @include flexbox; }

    &__input {
        @extend .text--regular;
        font-size: 0.75rem;
        background: #2c2420;
        box-shadow: none;
        font-weight: 500;
        padding: 2px 6px;
        border: solid 1px #80746a;
        width: 48px;
        text-align: right;
        height: 24px;
        margin: 0 3px;

        &:focus, &:hover {
            border-color: $orange-text;
            outline: 0; } }

    &__total {
        font-size: 0.75rem;

        &-label {
            @extend .text--regular;
            font-size: 0.75rem;
            font-weight: 500;
            color: #989898;
            margin: 0 5px; } }

    &__button {
        @extend .text--regular;
        font-size: 0.75rem;
        font-weight: 500;
        padding: 0 2px;

        &:focus, &:hover {
            color: $orange-text; }

        &.active {
            font-weight: 700;
            pointer-events: none;
            opacity: 0.6; } }

    &__next,
    &__previous {
        @extend .button--full-framed;
        border-color: #b5a799;
        padding: 4px 17px;
        font-size: 0.75rem;
        height: 24px;

        &:focus {
            color: #fff; }

        >.pagination__previous__icon__hover {
            height: 0.5rem;
            margin-top: -2px;
            margin-right: 5px;
            display: none; }
        // &:focus, &:hover

        &:hover {
            border-color: $orange-text;
            outline: none;
            >.pagination__previous__icon {
                display: none; }
            >.pagination__previous__icon__hover {
                display: inline-block; } }

        .icon {
            font-size: 0.46rem;
            position: relative;
            top: -1px; }

        &__icon {
            height: 0.5rem;

            &.hover {
                display: none; }

            &:hover {
                display: none;

                &.hover {
                    display: inline-block; } } } }

    &__previous {
        margin-right: 5px;

        &__icon {
            margin-right: 5px;
            margin-top: -2px; } }

    &__next {
        margin-left: 5px;

        .icon {
            margin-left: 5px; } }

    &-list {
        @include flexbox;
        @include align-items(center);
        @include justify-content(flex-end);
        padding: 15px 0; }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            display: inline-block;
            margin: 0 5px;
            font-size: 0.75rem; } } }
