.error {
    @extend .text--regular;
    @extend .container--full;
    @extend .container--main;
    @include flexbox;
    @include align-items(stretch);
    @include justify-content(center);
    @include flex-direction(column);
    @extend .text--regular;
    padding-left: 10px;
    padding-right: 10px;

    &__header {
        font-weight: 600;
        font-size: 1rem;
        @include respond-to(small) {
            font-size: 1.3rem; } }

    &__subheader {
        color: $orange-text;
        font-size: 1.2rem;
        margin: 10px 0;
        @include respond-to(small) {
            margin: 20px 0;
            font-size: 1.5rem; } }

    &__apo {
        color: $orange-text;
        font-weight: 600;
        margin-top: 10px; }

    &__content {
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 1.2rem;
        @include respond-to(small) {
            line-height: 1.5rem; } }

    &__inner {
        text-align: left;
        max-width: 800px;
        position: relative; }

    &__link {
        @extend .link--light;
        position: relative;
        margin-right: 20px;
        margin-bottom: 2px;
        &:before {
            content: '';
            background: #6f6153;
            display: inline-block;
            position: absolute;
            right: -10px;
            top: 4px;
            height: calc(100% - 7px);
            width: 1px; }
        &:last-of-type {
            &:before {
                display: none; } } }

    &__button {
        @extend .link--light;
        cursor: pointer;
        padding-left: 0;
        padding-right: 0; }

    &__links {
        margin-top: 20px;
        &__what {
            font-style: italic;
            font-weight: 600; } } }

