.users {
    @extend .container--main;
    padding-top: 10px;
    @include flexbox;
    @include flex-direction(column);

    &__list {
        @extend .table;
        list-style: none;
        padding: 5px;
        border: solid 1px #5b4f48;
        @include flex(1);

        @include respond-to('small') {
            padding: 20px; }

        &__title {
            @extend .table__title; }


        &__item {
            @extend .table__item;

            &__field {
                @extend .table__item__field;

                &--email {
                    @extend .users__list__item__field;
                    @include flex(1);
                    @include respond-to('large') {
                      @include flex(0 1 350px); } }

                &--activity {
                    @extend .users__list__item__field;
                    @include flex(1);
                    @include respond-to('large') {
                      @include flex(0 1 150px); } }

                &--login {
                    @extend .users__list__item__field;
                    @include flex(1);
                    font-weight: 600;
                    @include respond-to('large') {
                      @include flex(0 1 300px); } }

                &--role {
                    @extend .users__list__item__field;
                    @include flex(1);
                    @include respond-to('large') {
                       @include flex(0 1 350px); } }

                &--plain {
                    @extend .table__item__field--plain; }

                &--password {
                    @extend .users__list__item__field; } } }

        &__header {
            @extend .table__header;

            &__field {
                @extend .table__header__field;
                &--email {
                    @extend .users__list__header__field;
                    @include flex(0 1 350px); }
                &--activity {
                    @extend .users__list__header__field;
                    @include flex(0 1 150px); }
                &--role {
                    @extend .users__list__header__field;
                    @include flex(0 1 350px); }
                &--login {
                    @extend .users__list__header__field;
                    @include flex(0 1 300px); }
                &--password {
                    @extend .users__list__header__field; } } } }

    &__form {
        @extend .table__form;
        max-width: 250px;
        @include flexbox;
        @include align-items(center);

        &-container {
            width: 100%; }

        &__field {
            @extend .table__form__field;
            @include flex(1); }

        &__buttons {
            @extend .table__form__buttons;

            &--hidden {
                @extend .table__form__buttons--hidden; } }

        &__button {
            @extend .table__form__button; }

        &__activate {
            @extend .table__form__activate; }

        &__trigger {
            @extend .table__form__trigger; } }

    &__new-user {
        @extend .table__form__trigger;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
        span {
            margin-left: 20px; } }

    &__top-bar {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-end); }

    .modal {
        &__box {
            max-width: 450px; } } }


