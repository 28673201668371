.texts {
    @extend .container--main;
    @include flexbox;
    @include align-items(stretch);
    @include justify-content(center);
    @include flex-direction(column);
    @extend .text--regular;
    padding-top: 50px;

    &__inner {
        @include flexbox;
        @include flex(1);
        @include align-items(center);
        @include justify-content(center);
        padding: 0 50px;
        @include respond-to('medium') {
            padding: 0; }
        @include respond-to('large') {
            padding: 0 50px; } }

    &__list {
        list-style: none;
        padding: 0;
        position: relative;
        @extend .with-lines;
        @include flexbox;
        @include flex-wrap(wrap);
        @include flex(1 1 0px);
        &:before {
            top: -55px; }
        &:after {
            bottom: -55px; }
        &:before, &:after {
            background: #6f6153; }

        &__item {
            display: inline-block;
            margin: 5px 7px;
            text-align: center;
            flex-basis: 100%;
            min-width: 200px;
            @include respond-to('medium') {
                @include flex(1 1 calc(100%/3 - 34px)); }

            @include respond-to('large') {
                margin: 12px 17px; }

            &__link {
                @extend .button--framed;
                font-size: 0.83rem; } } }

    &.modal {
        .modal {
            &__box {
                max-width: 550px; }
            &--form {
                &__inner-group {
                    @include flexbox;
                    padding: 0 10px;
                    @include respond-to('small') {
                        padding: 0 20px; }
                    @include respond-to('medium') {
                        padding: 0 50px; } }
                &__field {
                    padding: 0 10px 0 20px;
                    &__group {
                        @include flex(1);
                        position: relative; }
                    &__tooltip {
                        &__button {
                            border: solid 1px #685e55;
                            color: #fff;
                            position: absolute;
                            left: -10px;
                            padding: 1px 2px 3px;
                            font-size: 0.8rem;
                            top: 35px;
                            @include respond-to('small') {
                                top: 20px; }
                            &:before {
                                content: '';
                                display: inline-block;
                                width: 12px;
                                height: 7px;
                                border-top: solid 1px #d6d6d6;
                                border-left: solid 1px #d6d6d6;
                                position: absolute;
                                top: -12px;
                                left: 10px; }
                            &:after {
                                content: '';
                                display: inline-block;
                                width: 12px;
                                height: 7px;
                                border-bottom: solid 1px #d6d6d6;
                                border-left: solid 1px #d6d6d6;
                                position: absolute;
                                top: 22px;
                                left: 10px; }
                            &:hover, &:focus {
                                background: #1f1d1b;
                                color: $orange-text;
                                border-color: #fff; } } } } } }

        .form {
            &__field {
                &__label {
                    margin-right: 10px;
                    @include respond-to('small') {
                        @include flex(0 1 110px); } } } }

        .select {
            &--table {
                &__control {
                    background: #373028;
                    border-color: #685e55;
                    &--is-focused {
                        border-color: $orange-text; } }

                &__value-container {
                    border-right: solid 1px #685e55; }

                &__indicator {
                    svg {
                        color: #cacaca; } }

                &__menu {
                    border-color: #685e55; } } } } }
