.app {
    @include flexbox;
    @include flex-direction(column);
    border-top: solid 1px #947755;
    // height: 100%
    min-height: calc(100vh - 1px);
    @include flex(1 1 100vh);
    &.fixed {
        overflow: hidden;
        position: fixed;
        width: 100%; } }

.blured {
    filter: blur(2px); }

.root {
    // background-image: url("../images/bg_tall.jpg")
    background-image: url("../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #211a16;
    background-position: top center;
    @include respond-to('large') {
        // background-image: url("../images/bg.jpg")
        height: calc(100vh - 1px); }
    // +flexbox
    // +flex-direction(column)
    // min-height: 100%
 }    // height: calc(100vh - 1px)

.container {
    &--main {
        @include flex(1 0 auto);
        padding-bottom: 50px; }
    &--full {
        margin: 0 auto;
        width: 100%;
        @include respond-to('medium') {
            max-width: 750px; }
        @include respond-to('large') {
            max-width: 1350px; }
        @include respond-to('xlarge') {
            max-width: 1800px; } } }
