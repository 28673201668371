html {
    font-size: 16px;
    &.medium {
        font-size: 18px; }
    &.big {
        font-size: 20px;

        scroll-behavior: smooth; } }

// input:focus, textarea:focus, select:focus
//   outline: none

body {
    margin: 0;
    padding: 0;
    font-family: 'Encode Sans', sans-serif;
    height: 100vh;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
@include respond-to(small) {
    // min-height: 700px
    background-color: #211a16; }

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

label {
    margin-bottom: 0; }

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    cursor: pointer; }

::-webkit-scrollbar-thumb {
    background: #6E655E;
    border-radius: 5px; }

::-webkit-scrollbar-thumb:hover {
    background: #b08563; }

::-webkit-scrollbar-track {
    background: #49423c;
    border-radius: 0px; }


.list-box-shadow {
    @include respond-to(large) {
        box-shadow: inset 0px -20px 20px -10px rgba(0, 0, 0, 0.4);
        transition: box-shadow 0.3s ease-in-out;
        &--hiddden {
            box-shadow: inset 0px -20px 20px -10px rgba(0, 0, 0, 0); } } }

