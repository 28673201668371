a {
  text-decoration: none;
  &.disabled {
    pointer-events: none;
    text-decoration: none; }
  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline; } }

.link {
  &--underlined {
    padding: 0 7px;
    @extend .text--regular;
    font-weight: 600;
    width: 100%;
    display: inline-block;
    position: relative;
    border-bottom: solid 1px transparent;
    transition: all 0.2s ease-in-out;
    @include respond-to('large') {
      padding: 0 10px; }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 8px;
      width: calc(100% - 16px);
      height: 1px;
      background: #6f6153;
      @include respond-to('large') {
        left: 10px;
        width: calc(100% - 20px); } }
    &:hover, &:focus, &:active {
      text-decoration: none;
      &::before, &::after {
        content: "";
        width: 1px;
        position: absolute;
        background: $orange-text;
        height: 7px; }
      &::before {
        left: 0;
        bottom: 0; }
      &::after {
        left: 100%;
        bottom: -1px;
        height: 8px; } }
    &:hover, &:focus {
      color: $orange-text;
      border-bottom: solid 1px $orange-text;
      &::before, &::after {
        background: $orange-text; } }
    &:active {
      color: #fff;
      border-color: #fff;
      &::before, &::after {
        background: #fff; } }
    // span
    //   display: block
    //   &::before
    //     left: 0
    //     bottom: 0
    //     transition-duration: 0.4s
    //   &::after
    //     left: 100%
    //     bottom: -1px
    //     height: 8px
 }    //     transition-duration: 0.4s
  &--dark {
    @extend .text--regular;
    font-weight: 600;
    width: 100%;
    display: inline-block;
    position: relative;
    border-bottom: solid 1px transparent;
    transition: all 0.2s ease-in-out; }

  &--light {
    @extend .text--regular;
    display: inline-block;
    position: relative;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    &::after {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #6f6153; }
    &:hover, &:focus {
      color: $orange-text;
      text-decoration: none;
      &::after {
        background: $orange-text; } }
    &:active {
      color: #fff;
      &::after {
        background: #fff; } } } }
