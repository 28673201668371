.words {
    &__searchbar {
        width: 100%;
        margin: 30px 0 0;
        z-index: 200;
        display: flex;
        position: relative;
        @include respond-to('large') {
            margin: 20px 0 10px; }

        >.react-autosuggest__container {
            flex: 1; }

        &__search-button {
            @extend .button--full-framed;
            border: solid 1px #685e55;
            background: #373028;
            padding: 7px 10px;
            z-index: 1;
            border-left: none;
            height: 38px; }

        &__help-button {
            @extend .words__searchbar__search-button;
            padding: 10px; } }

    &__breadcrumbs {
        display: flex;
        align-items: flex-end;
        max-height: 36px; }

    &__filters {
        max-width: 100%;
        // max-height: calc(100vh - 388px)
        display: grid;
        grid-template-rows: auto 1fr auto;
        @include respond-to('large') {
            max-width: 450px;
            height: 100vh;
            max-height: calc(100vh - 322px);

            &.high {
                max-height: calc(100vh - 300px); } }

        &__container {
            padding: 5px 15px;
            border: 1px solid #5B4F48;
            font-size: 0.75rem;
            font-weight: 300;
            @include respond-to('large') {
                overflow: auto; } }

        &__buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;

            @include respond-to('to-large') {
                // background-color: rgba(52, 44, 41, 0.8)
                // border-top: 1px solid #5B4F48
                position: sticky;
                bottom: 0;
                padding: 15px 0 10px;
                margin-top: 0; }

            &--bcg {
                // +respond-to('to-medium')
                // background: pink
                background-color: rgb(52, 44, 41);
                position: absolute;
                top: -2px;
                left: -9px;
                width: calc(100vw - 11px);
                z-index: -1;
                height: 60px;
                // background-color: rgb(52, 44, 41)
                border-top: 1px solid #5B4F48;

                @include respond-to('medium') {
                    left: calc(-50vw + 370px);
                    width: 100vw; }

                @include respond-to('large') {
                    display: none; } } }

        &__tabs {
            display: grid;
            grid-template-columns: 180px 180px 1fr;
            margin-top: 20px;
            font-size: 12px;
            color: #fff;
            font-weight: 700; }

        &__tab {
            border: 1px solid #564c46;
            border-bottom: 1px solid #E6AB64;
            padding: 10px 20px;
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: center;

            &.active {
                border: 1px solid #E6AB64;
                border-bottom: 1px solid transparent;
                pointer-events: none;
                cursor: default;
                color: #e19d4d;

                >span {
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    top: auto;
                    top: initial;
                    left: 15px;
                    height: 2px;
                    width: calc(100% - 30px);
                    background: #e19d4d; } }

            &.dummy {
                border: none;
                border-bottom: 1px solid #E6AB64; }

            &__notab-head {
                font-size: 0.8125rem;
                padding-bottom: 12px;
                border-bottom: 1px solid #594F49;
                font-weight: 700;
                margin: 15px 0; } } }

    &__heading {
        font-size: 0.8125rem;
        margin: 20px 0 20px;
        > span {
            font-weight: 300; }

        @include respond-to(large) {
            margin: 30px 0 20px; } }

    &__results {
        display: flex;
        flex-direction: column;
        height: inherit;
        margin-top: 0;

        @include respond-to('medium') {
            margin-top: 20px; }

        @include respond-to(large) {
            margin-top: 0;
            max-height: calc(100vh - 317px); }

        &__head {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column-reverse;
            //z-index: 200 //commented due the APO-444
            max-width: 550px;
            @include respond-to('medium') {
                flex-direction: row;
                align-items: center; } }

        &__container {
            display: flex;
            flex-direction: column; }

        &__results {
            border: 1px solid #5B4F48;
            flex: 1 1; }

        &__list {
            border: 1px solid #5B4F48;
            flex: 1 1;
            padding: 15px;
            overflow-y: auto;
            // max-height: calc(100vh - 445px)
            // max-height: calc(100vh - 382px)

            &__item {
                width: 100%;
                margin-bottom: 8px;
                border: 1px solid #5B4F48;
                padding: 4px 20px;
                font-size: 0.75rem;
                cursor: pointer;

                >.hits {
                    font-weight: 300;
                    color: #afafaf; }

                &:hover {
                    border-color: #9e7d54;
                    background: #2e2721; } }

            &__no-results {
                color: #e19d4d;
                font-size: 0.75rem; } } }

    &__filters-card {
        @extend .card;
        padding: 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #594F49;
        padding-bottom: 10px;

        &--last {
            @extend .words__filters-card;
            border: none; }

        &__header {
            color: white;
            cursor: pointer;
            font-size: 0.75rem;
            display: flex;
            border: 1px solid transparent;
            border-radius: 3px;
            padding: 5px 8px;
            justify-content: space-between;
            margin-top: 10px;
            margin-left: -8px;
            font-weight: 700;
            transition: all 0.2s ease-in-out;
            &:hover {
                border: 1px solid #e19d4d;
                color: #e19d4d; } }

        &__body {
            padding: 10px 0 0; } }

    &__chevron {
        transform: rotate(-180deg);
        transition-duration: 0.4s;
        transition-property: transform;

        &.expanded {
            transform: none; } }

    &__container {
        display: grid;
        column-gap: 15px;

        @include respond-to(large) {
            height: calc(100vh - 260px);
            grid-template-columns: 450px 1fr;
            grid-template-rows: 80px 1fr; } }

    &__tabmenu {
        display: flex;

        &__tab {
            padding: 7px 15px;
            background: #fff;
            border: solid 1px #CBB699;
            color: #414141;
            font-size: 0.75rem;
            font-weight: 600;
            // max-width: fit-content
            z-index: 100;
            &.active {
                cursor: pointer; }
            &.inactive {
                opacity: 0.7;
                mouse-events: none; }
            @include respond-to('large') {
                padding: 7px 50px; } } }

    &__pagination {
        justify-content: flex-end;
        margin-top: -33px;
        margin-bottom: 9px; }

    &__sort-method {
        &__select {
            margin-left: 0;
            margin-bottom: 20px;
            @include respond-to('medium') {
                margin-left: 15px;
                margin-bottom: 0; }
            @include respond-to('large') {
                margin-left: 30px; } } }

    &__hidden {
        @include respond-to("to-large") {
            display: none; } }

    &__text-back {
        margin: 0 0 10px 0;
        padding: 0 0 7px 0;
        border-bottom: solid 1px #5b4f48; }

    &__selected-word {
        margin: 20px 0;
        font-size: 0.8125rem;

        >span {
            font-weight: 300; }

        >.hits {
            color: #afafaf; } }

    &__export-csv {
        background-color: transparent;
        border: 1px solid #594F49;
        padding: 5px;
        height: 28px;
        font-weight: 300;
        font-size: 0.75rem;
        margin: 0 0 15px;
        padding: 5px 15px;
        cursor: pointer;
        color: white;
        position: relative;
        transition: color 0.2s ease-in-out;

        >.page__loader {
            height: 40px;
            position: absolute;
            left: 39px;
            top: -7px; }

        @include respond-to(medium) {
            margin: -5px 0 0 15px; }

        @include respond-to(large) {
            margin: -5px 0 0 20px; }

        &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.5; }

        &:hover {
            color: #e19d4d;
            text-decoration: none; } }

    &__error {
        font-size: 10px;
        color: #e19d4d;
        position: absolute;
        top: 40px; } }

.words-select {
    margin-top: -5px;
    z-index: 300;

    .select--table__single-value {
        font-size: inherit; }

    .select--table__placeholder {
        font-size: inherit; }

    .select--table__option {
        font-size: 0.75rem;
        padding: 7px 20px 7px 25px; }

    .select--table__option--is-focused {
        background: none; }

    .select--table__option--is-selected:before {
        content: none;
        // left: 13px
        // top: 9px
        // height: 8px
        // width: 4px
        // border-bottom: 2px solid #fff
 }        // border-right: 2px solid #fff

    .select--table__indicator {
        color: #e19d4d; }

    .select--table__control {
        min-width: 200px;
        background-color: transparent;
        border: 1px solid #594F49;
        padding: 5px;
        height: 28px;
        font-weight: 300;
        font-size: 0.75rem; } }
