.modal {
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 999999;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    background: rgba(0, 0, 0, 0.4);

    &__loading {
        position: relative; }

    &__box {
        background-color: #483f36;
        margin: 0 auto;
        border: solid 1px #dfdfdf;
        width: 100%;
        max-width: 600px; }

    &__inner {
        @extend .text--regular;
        padding: 12px;
        text-align: center;
        font-weight: 500;
        font-size: 0.83rem; }

    &__top {
        height: 26px;
        background: #dfdfdf;
        text-align: right;
        &.hasName {
            display: flex;
            justify-content: space-between;
            color: #616161;
            font-size: 0.75rem;
            font-weight: 600;
            height: 40px; }

        &--title {
            margin: 15px 30px; } }

    &__close {
        font-size: 0.6rem;
        color: #483f36;
        &:hover, &:focus {
            color: $orange-text; }
        &:active {
            color: #000; } }

    &__content {
        border: solid 1px #2b2621; }

    &__header {
        @extend .text--regular;
        color: $orange-text;
        text-align: center;
        position: relative;
        text-transform: uppercase;
        font-size: 0.66rem;
        letter-spacing: 1px;
        padding-bottom: 8px;
        border-bottom: solid 2px $orange-text;
        margin: 0 30px 2px 30px;
        &:before {
            content: '';
            display: inline-block;
            background-color: #483f36;
            position: absolute;
            left: -10px;
            bottom: -6px;
            height: 3px;
            width: calc(100% + 20px); } }

    &--form {
        &__row {
            display: grid;
            grid-template-columns: 1fr 90px;
            min-width: 500px;
            grid-column-gap: 15px;
            margin: 0 1rem 1rem; }

        &__content {
            @extend .modal__content;
            padding: 20px 10px 10px 10px;
            @include respond-to(small) {
                padding: 30px 10px 10px 10px; } }

        &__inner {
            @extend .modal__inner;
            padding: 25px 12px 12px;
            font-weight: 600;
            font-size: 0.9rem;
            // max-width: 400px
            &-wide {
                width: 90vw;
                max-width: 550px; } }

        &__header {
            @extend .modal__header; }

        &__error {
            @extend .form__field__error;
            text-align: center;
            font-size: 0.83rem;
            font-weight: 700;
            margin-bottom: 10px;
            @include respond-to(small) {
                margin-bottom: 20px; } }

        &__info {
            @extend .form__field__error;
            text-align: center;
            font-size: 0.83rem;
            color: #fff;
            font-weight: 500;
            margin-bottom: 10px;
            @include respond-to(small) {
                margin-bottom: 20px; } }

        &__upload {}


        &__field-selects {
            width: 140px;
            padding: 0;
            margin-bottom: 5px;
            @include respond-to(small) {
                padding: 0 70px 0 10px;
                margin-bottom: 20px; }
            .select--table__single-value {
                font-size: 0.66rem;
                padding: 0; }
            .select--table__option {
                font-size: 0.66rem; }

            .select--table__input {
                padding: 0;
                font-weight: 500;
                font-size: 0.66rem; }
            >.form__field {
                width: 130px; } }


        &__field {
            padding: 0;
            margin-bottom: 5px;
            @include respond-to(small) {
                padding: 0 70px 0 10px;
                margin-bottom: 20px; }
            .select--table__single-value {
                font-size: 0.66rem;
                padding: 0; }
            .select--table__option {
                font-size: 0.66rem; }

            .select--table__input {
                padding: 0;
                font-weight: 500;
                font-size: 0.66rem; }

            &-column {} }


        &__buttons {
            @extend .form__buttons; }

        &__button {
            @extend .form__button;
            &--clear {
                @extend .form__button--clear; } } } }
