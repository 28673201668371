.manage-text {
    @extend .container--main;
    padding-top: 10px;
    @include flexbox;
    @include flex-direction(column);

    &__top-bar {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-end); }

    &__list {
        @extend .table;
        list-style: none;
        padding: 5px;
        border: solid 1px #5b4f48;
        @include flex(1);
        @include respond-to('small') {
            padding: 20px; }

        &__title {
            @extend .table__title; }


        &__item {
            @extend .table__item--link;
            @include flex-direction(row);
            @include align-items(stretch);

            &--border {
                position: relative;
                padding: 5px 10px;
                border: solid 1px #5b4f48;
                flex: 1 1;
                color: #fff;
                @include respond-to('large') {
                    display: flex; } }


            & > a {
                position: relative;
                &:hover, &:focus {
                    .manage-text__list__item__field__img {
                        border-color: #b68245;
                        &:before, &:after {
                            background: #b68245; } }
                    .icon {
                        color: #fff; } } }

            &__tooltip {
                @extend .table__item__tooltip;
                left: 100px;
                @include respond-to('small') {
                    left: 200px; } }

            &__field {
                @extend .table__item__field;
                font-weight: 600;

                .icon {
                    font-size: 0.73rem;
                    margin-right: 5px; }

                &__img {
                    width: 30px;
                    padding-right: 10px;
                    border-right: solid 1px #5b4f48;
                    margin-right: 10px;
                    position: relative;
                    display: none;
                    &:before,
                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        width: 1px;
                        height: 5px;
                        right: -1px;
                        background: #5b4f48; }
                    &:before {
                        top: -5px; }
                    &:after {
                        bottom: -5px; }
                    @include respond-to('large') {
                        display: inline-block; } }

                &:before {
                    @include respond-to('small') {
                        @include flex(0 0 150px); } }

                &--name {
                    @extend .manage-text__list__item__field;
                    @include flex(1);
                    &:before {
                        text-transform: none; }
                    @include respond-to('large') {
                      @include flex(0 1 400px); } }

                &--status {
                    @extend .manage-text__list__item__field;
                    @include flex(1);
                    &:before {
                        text-transform: none; }
                    @include respond-to('large') {
                      @include flex(0 1 250px); } }

                &--button {
                    @extend .manage-text__list__item__field;
                    @include flex(0 1 100px);
                    &:before {
                        text-transform: none;
                        display: none; }
                    @include respond-to('large') {
                      @include flex(0 1 150px); } } } }

        &__header {
            @extend .table__header;

            &__field {
                @extend .table__header__field;
                &--name {
                    @extend .manage-text__list__header__field;
                    @include flex(0 1 400px); }
                &--status {
                    @extend .manage-text__list__header__field;
                    @include flex(0 1 250px); }
                &--button {
                    @extend .manage-text__list__header__field;
                    @include flex(0 1 150px); } } } }

    &__button {
        @extend .table__form__trigger;
        width: 100%;
        height: 100%; }

    &__status {
        &__other {
            margin-right: 5px;
            font-weight: 600;
            color: #a5a5a5; } }

    &.modal {
        .modal {
            &__box {
                max-width: 500px; } } } }




