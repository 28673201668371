.dropdown {
    position: relative;
    &__box {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        z-index: 2;
        background: #483f36;
        border: solid 1px #685e55; }
    &__button {
        @extend .link--light;
        position: relative;
        padding-left: 10px;
        padding-right: 25px;
        padding-top: 3px;
        font-weight: 600;
        border: solid 1px transparent;
        .hover, .focus {
            display: none; }
        &:hover, &:focus {
            .hover, .focus {
                display: inline-block; }
            .regular {
                display: none; } }
        &:active {
            .hover, .focus {
                display: none; }
            .regular {
                display: inline-block; } }
        &:after {
            width: calc(100% - 20px);
            left: 10px;
            bottom: 0; }
        &.active {
            border: solid 1px #b78347;
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                height: 1px;
                background: #b78347; } }
        &__img {
            position: absolute;
            bottom: 7px;
            right: 10px;
            width: 8px; } } }
