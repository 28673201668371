.footer {
    background-color: #000000;
    border-top: solid 1px #947755;
    @extend .text--additional;
    display: inline-block;
    width: 100%;

    &__link {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        font-size: 0.5rem;
        font-weight: 600;
        transition: all 0.2s ease-in-out;
        margin-right: 10px;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #6f6153; }

        &:hover, &:focus {
            color: $orange-text;
            &:after {
                background: $orange-text; } }
        &:active {
            color: #fff;
            &:after {
                background: #fff; } } }

    &__inner {
        @extend .container--full;
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(center);
        padding-top: 5px;
        padding-bottom: 5px; }

    &__main-nav {
        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &-item {
                display: inline-block;
                @include respond-to('small') {
                    margin-right: 10px; }
                @include respond-to('xlarge') {
                    margin-right: 30px; }
                &:last-of-type {
                    margin-right: 0; }

                &__link {
                    @extend .footer__link;
                    @extend .text--additional; } } } }

    &__part-container {
        display: inline-block;
        position: relative;
        margin: 5px 10px 5px 0;
        padding-left: 10px;
        text-align: center;
        @include respond-to('medium') {
            margin: 5px 10px 5px 0; }
        @include respond-to('xlarge') {
            padding-left: 30px;
            margin: 5px 30px 5px 0; }
        &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            height: calc(100% - 2px);
            width: 1px;
            background: #4d3e2e; }
        &:first-of-type {
            padding-left: 0;
            margin: 5px 0;
            @include flex(1 1 100%);
            @include respond-to('small') {
                margin: 5px 10px; }
            @include respond-to('medium') {
                @include flex(none);
                margin: 5px 10px 5px 0; }
            &:before {
                display: none; } }
        &:nth-of-type(2) {
            padding-left: 0;
            @include respond-to('medium') {
                padding-left: 10px; }
            &::before {
                display: none;
                @include respond-to('medium') {
                    display: block; } } }

        &--right {
            margin: 5px 0 5px 10px;
            padding-left: 10px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 2px;
                left: 0;
                height: calc(100% - 2px);
                width: 1px;
                background: #4d3e2e; }
            @include respond-to('large') {
                text-align: right;
                margin-left: auto;
                @include flex(1);
                &::before {
                    left: 10px; } }
            @include respond-to('xlarge') {
                white-space: nowrap;
                &:before {
                    display: none; } } } }

    &__page {
        &__link {
            @extend .footer__link;
            @extend .text--fancy;
            font-size: 0.65rem;
            font-weight: 700;
            letter-spacing: 0;
            margin-left: 3px;

            &-icon {
                @extend .external-link; } }

        &__logo {
            height: 23px;
            display: inline-block;
            position: relative;
            top: 7px;
            margin-top: -12px;

            &-link {
                @extend .text--additional;
                position: relative; } }

        &__name {
            @extend .text--additional;
            text-transform: uppercase;
            font-size: 0.5rem;
            font-weight: 600;
            line-height: 0.75rem;
            display: none;
            @include respond-to('xlarge') {
                display: inline-block; } }

        &__credits {
            @extend .text--additional;
            text-transform: uppercase;
            color: #807360;
            font-size: 0.5rem;
            display: none;
            @include respond-to('large') {
                display: inline-block; }
            &-link {
                @extend .footer__link;
                @extend .text--additional;
                color: #fff;
                font-size: 0.5rem;
                margin-left: 3px; } } } }
