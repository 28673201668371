.tooltip {
    position: relative;
    &__box {
        border: solid 1px #cccccc;
        background: #373028;
        position: absolute;
        z-index: 2;
        top: -10px;
        left: 60%;
        width: 225px;
        @include respond-to('small') {
            top: -24px;
            left: 100%; }
        &__content {
            @extend .text--regular;
            font-weight: 500;
            padding: 10px 5px 10px 20px;
            text-align: left;
            position: relative;
            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                height: 40px;
                width: 7px;
                border: solid 1px #685e55;
                border-left: none;
                left: 5px;
                top: 15px; } }

        &__header {
            display: flex;
            justify-content: space-between;
            background: #cccccc;
            text-align: left;
            color: #373028; } }
    &__text {
        &__header {
            font-size: 0.6rem;
            margin-bottom: 5px;
            font-weight: 600;
            color: #fefefe; }
        &__inner {
            font-size: 0.6rem;
            color: #bababa; } } }
