.app.contrast {
    background-color: #000 !important;
    border-color: #ffe400 !important;
    color: #ffe400 !important;
    &:before {
        content: '';
        background-color: rgba(0,0,0,1);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }
    * {
        color: #ffe400 !important;
        border-color: #ffe400 !important;
        background-color: #000 !important; }
    .header__top__menu-toggle {
        border-color: #ffe400 !important;
        &:hover, &:focus {
            border-color: #ffe400 !important; }
        span {
            background: #ffe400 !important; } }
    button, input, a {
        border-color: #ffe400 !important;
        color: #ffe400;
        background-color: #000 !important;
        &.table__form__activate {
            background-color: transparent !important; } }
    input {
        color: #fff;
        @include placeholder {
            color: #fff; } }
    a {
        &:before,
        &:after {
            background-color: #ffe400 !important; } }
    .results__trigger {
        border: solid 1px #000 !important;
        color: #000;
        background-color: #ffe400 !important;
        &:hover, &:focus {
            border: solid 1px #ffe400 !important;
            color: #ffe400;
            background-color: #000 !important;
            .results__trigger__img {
                background-color: #000 !important; } }
        &__img {
            background-color: #ffe400 !important; } } }


body.contrast {
    .root {
        background-color: #000 !important;
        border-color: #ffe400 !important;
        color: #ffe400 !important; }
    .modal {
        * {
            color: #ffe400 !important;
            border-color: #ffe400 !important;
            background-color: #000 !important; }
        &--form__header {
            &:before {
                background-color: #ffe400; } } } }

